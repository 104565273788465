<template>
  <div>
    <h2>Les offres</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          aria-current="page"
        >
          <router-link :to="{ name: 'CompanyOffersList' }">
            Mes recrutements
          </router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Détails d'une offre
        </li>
      </ol>
    </nav>
    <div
      v-if="notifVerification?.type === 'refusé'"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <h4>
        Les dernières modifications de cette offre ont été refusées
      </h4>
      Motif : {{ notifVerification?.ground_of_refusal }}
    </div>
    <div
      v-if="notifVerification?.type === 'vérifié'"
      class="alert alert-green alert-dismissible fade show"
      role="alert"
    >
      <h4>
        Les dernières modifications de cette offre ont été validées
      </h4>
    </div>
    <h4 class="text-primary text-bold">
      Détails de l'offre : {{ offer?.title }}
    </h4>
    <div class="row mt-4 align-items-center">
      <div class="col col-12 col-sm-6 col-md-2">
        <label class="text-white-50 text-bold mb-2">Types de contrats</label>
        <p>
          {{ offer?.contract_types.map(c => c.name).join(" - ") }}
        </p>
      </div>
      <div
        v-if="offer?.city"
        class="col col-12 col-sm-6 col-md-3"
      >
        <label class="text-white-50 text-bold">
          Ville
        </label>
        <p> {{ offer?.city?.name }} </p>
      </div>

      <div
        v-else-if="offer?.telework === 'full'"
        class="col col-12 col-sm-6 col-md-2"
      >
        <label class="text-white-50 text-bold"> Télétravail </label>
        <p> 100% télétravail </p>
      </div>
      <div class="col col-12 col-sm-6 col-md-3">
        <label class="text-white-50 text-bold">
          Nombre de postes ouverts
        </label>
        <p>{{ offer?.number_of_seats ? offer?.number_of_seats : 'Non renseigné' }}</p>
      </div>
      <div class="col col-12 col-sm-6 col-md-2">
        <label class="text-white-50 text-bold">
          Vidéos de présentation obligatoires
        </label>
        <p>{{ offer?.p_videos_required ? 'Oui' : 'Non' }}</p>
      </div>
      <div class="col text-md-end col-12 col-sm-6 col-md-3">
        <router-link :to="{ name: 'CompanyOfferUpdate', params : { id : route.params.id } }">
          <button class="btn btn-purple-gradient text-bold">
            Modifier l'offre
          </button>
        </router-link>
      </div>
    </div>

    <hr class="w-100">
    <div class="row text-end">
      <router-link
        :to="{ name: 'CompanyOfferCreate' }"
      >
        <button class="btn btn-purple-gradient text-bold">
          Publier une nouvelle offre
        </button>
      </router-link>
    </div>
    <ul
      id="myTab"
      class="nav nav-pills"
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="all-application-tab"
          class="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#all-application"
          type="button"
          role="tab"
          aria-controls="all-application"
          aria-selected="true"
          @click="allMatch"
        >
          <span>
            Toutes
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="new-application-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#new-application"
          type="button"
          role="tab"
          aria-controls="new-application"
          aria-selected="true"
          @click="newMatch"
        >
          <span>
            Nouvelles
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="shortlisted-application-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#shortlisted-application"
          type="button"
          role="tab"
          aria-controls="shortlisted-application"
          aria-selected="false"
          @click="shortlistMatch"
        >
          <span>
            Shortlistées
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="refuse-application-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#refuse-application"
          type="button"
          role="tab"
          aria-controls="refuse-application"
          aria-selected="false"
          @click="refusedMatch"
        >
          <span>
            Refusées
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="accepted-application-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#accepted-application"
          type="button"
          role="tab"
          aria-controls="accepted-application"
          aria-selected="false"
          @click="recruitedMatch"
        >
          <span>
            Abouties
            <div class="line-primary" />
          </span>
        </button>
      </li>
    </ul>
    <div
      class="tab-content"
    >
      <div v-if="matchings?.[0]">
        <div
          v-if="selectnav==='all'"
          id="all-application"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="all-application-tab"
        >
          <application-list :list="matchings" />
        </div>
        <div
          v-if="selectnav==='new'"
          id="new-application"
          class="tab-pane fade show"
          role="tabpanel"
          aria-labelledby="new-application-tab"
        >
          <application-list :list="matchings" />
        </div>
        <div
          v-if="selectnav==='shortlisted'"
          id="shortlisted-application"
          class="tab-pane fade show"
          role="tabpanel"
          aria-labelledby="shortlisted-application-tab"
        >
          <application-list :list="matchings" />
        </div>
        <div
          v-if="selectnav==='refused'"
          id="refuse-application"
          class="tab-pane fade show"
          role="tabpanel"
          aria-labelledby="refuse-application-tab"
        >
          <application-list :list="matchings" />
        </div>
        <div
          v-if="selectnav==='accepted'"
          id="accepted-application"
          class="tab-pane fade show"
          role="tabpanel"
          aria-labelledby="accepted-application-tab"
        >
          <application-list :list="matchings" />
        </div>
      </div>
      <div
        v-else
        class=" d-flex justify-content-center mt-5"
      >
        <h5>Pas de candidature...</h5>
      </div>
    </div>
    <div class="row g-4" />
  </div>
</template>

<script setup>

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import ApplicationList from '@/components/application/ApplicationList.vue';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();
const selectnav = ref('all');

const notifVerification = computed(() => store.state?.offer?.offer_detail?.data?.data?.['verification-notifications']);

const matchings = computed(() => store.state?.matching?.matchings?.data?.data);
const offer = computed(() => store.state?.offer?.offer_detail?.data?.data);

const allMatch = () => {
  store.dispatch('matching/getAll', { id: route.params.id });
  selectnav.value = 'all';
};

const newMatch = () => {
  store.dispatch('matching/getAll', { id: route.params.id, filter: { recruiter_step: 'new' } });
  selectnav.value = 'new';
};

const shortlistMatch = () => {
  store.dispatch('matching/getAll', { id: route.params.id, filter: { recruiter_step: 'accepted' } });
  selectnav.value = 'shortlisted';
};

const refusedMatch = () => {
  store.dispatch('matching/getAll', { id: route.params.id, filter: { recruiter_step: 'refused' } });
  selectnav.value = 'refused';
};

const recruitedMatch = () => {
  store.dispatch('matching/getAll', { id: route.params.id, filter: { recruiter_step: 'recruited' } });
  selectnav.value = 'accepted';
};

onMounted(() => {
  store.dispatch('offer/getOne', route.params.id);
  store.dispatch('matching/getAll', { id: route.params.id });
});
</script>
