export default {
  user: {},
  has_attempted_to_fetch_user: false,
  authentication_errors: [],
  authentication_loading: false,
  register_errors: [],
  register_loading: false,
  email_verified_loading: false,
  reset_password_success: false,
  reset_password_loading: false,
  reset_password_errors: [],
  forgot_password_success: false,
  forgot_password_loading: false,
  forgot_password_errors: [],
  unread_messages: false,
};
