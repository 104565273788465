<template>
  <div>
    <vue-cal
      id="myCalendar"
      ref="vuecal"
      active-view="week"
      style="height: 60vh"
      :hide-weekdays="[]"
      hide-view-selector
      :disable-views="[ 'day']"
      small
      :events="meetings"
      :editable-events="{ title: true, drag: true, resize: true, delete: true, create: true }"
      hide-weekends
      locale="fr"
      :snap-to-time="15"
      :time-from="9 * 60"
      :time-to="21 * 60"
      :on-event-create="onEventCreate"
      :drag-to-create-event="false"
      :time-cell-height="60"
      events-on-month-view="short"
      @cell-click="createEvent"
      @event-change="onEventUpdate"
    />
    <teleport to="body">
      <modal
        id="meeting-creation-confirmation-modal"
        :show-actions="false"
      >
        <template #header>
          <h5 class="modal-title">
            Invitation envoyée
          </h5>
          <button
            type="button"
            class="btn-close bg-light"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </template>
        <img
          class="me-3"
          src="@/assets/images/icons/icon-validate.svg"
          alt=""
        >
        <span>Votre demande de rendez-vous a bien été envoyée</span>
      </modal>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, defineExpose, onMounted, computed, defineProps, Teleport } from 'vue';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import { useStore } from 'vuex';
import Modal from '@/components/modal/Modal.vue';
import normalizer from "@/utils/normalizer";
import dayjs from 'dayjs';
import { Modal as BSModal } from 'bootstrap';

const props = defineProps({
  candidate: Object,
  recruiter: Object,
});

const store = useStore();

const meetings = computed(
  () => {
    if (store.state.meeting?.meetings?.data) {

      return store.state.meeting?.meetings?.data
      .map(m => ({
        start: new Date(m.start_date),
        end: new Date(m.end_date),
        title: m.description,
        draggable: false,
        resizable: false,
        titleEditable: false,
        class: m.status,
        // content: '<i class="icon material-icons">shopping_cart</i>',
      }));
    }
  return [];
  },
  );

const vuecal = ref(null);
const selectedEvent = ref(null);
const showEventCreationDialog = ref(false);
const deleteEventFunction = ref(null);
const meetingToSendToApi =ref(null);

const onEventCreate = (meeting, deleteEventFunc) => {
  deleteEventFunction.value = deleteEventFunc;

  let candidate = props.candidate;
  let recruiter = props.recruiter;

  meeting.title = (candidate.username || `${candidate.first_name} ${candidate.second_name}`) + ' - ' + `${recruiter.first_name} ${recruiter.second_name}` ;
  meeting.class = 'just-created';
  meetingToSendToApi.value = {
    recruiter_id: recruiter?.id,
    user_id: candidate.id,
    start_date: dayjs(meeting.start).format(),
    end_date: dayjs(meeting.end).format(),
    status: 'new',
    title: meeting.title,
  };
  return meeting;
};

const createEvent = async (event) => {

  if (!vuecal.value.isWeekOrDayView) {
    return;
  }

  if (new Date(event) < new Date()) {
    return;
  }

  if (meetingToSendToApi.value) {
    deleteEventFunction.value();
  }
  event.setMinutes(0);
  vuecal.value.createEvent(
    event,
    60,
  );
};


const showCreationConfirmationModal = () => {
  let meetingCreationConfirmationModalElement = document.getElementById('meeting-creation-confirmation-modal');
  new BSModal(meetingCreationConfirmationModalElement).show();
};

const cancelEventCreation = () => {
 closeCreationDialog();
 deleteEventFunction.value();
 meetingToSendToApi.value = null;
 if (deleteEventFunction.value) {
   deleteEventFunction.value();
 }
};

const closeCreationDialog = () => {
  showEventCreationDialog.value = false;
  selectedEvent.value = {};

};

const onEventUpdate = ({ event }) => {
  meetingToSendToApi.value.start_date = dayjs(event.start).format();
  meetingToSendToApi.value.end_date = dayjs(event.end).format();
  meetingToSendToApi.value.description = event.title;
};

const sendEvents = async () => {
  const result = normalizer.makeFormDataWithObject(meetingToSendToApi.value);
  await store.dispatch("meeting/create", {
    data: result,
    onSuccess: showCreationConfirmationModal,
  });
  store.dispatch("meeting/getAll",{ recruiter_id: props.recruiter?.id });
  meetingToSendToApi.value = null;
};


onMounted(() => {
  store.dispatch("meeting/getAll",{ recruiter_id: props.recruiter?.id });

});

defineExpose({
  sendEvents,
  cancelEventCreation,
});
</script>

<style lang="scss">
 #myCalendar {
  .vuecal__event-resize-handle {
    height: 0.2em !important;
  }
  .vuecal__event {
    &.just-created:hover {
      cursor: grab;
    }
  }
  .new {
    background-color: #6a20af94;
  }
  .just-created {
    background-color: #9720af94;
  }
  .approved {
    background-color: #1fbb3459;
  }
  .canceled {
    background-color: #bb1f1f94;
  }
  .vuecal__no-event {
    visibility: hidden;
  }
  .vuecal__event-time {
    visibility: hidden !important;
  }
  .vuecal__cell--selected {
    background-color: #cbb4e21e;
    // border: #7608b6 solid 2px !important;
  }
  .vuecal__cell--today {
    background-color: #cbb4e21e;
    // border: #7608b6 solid 2px;
  }
  .vuecal__menu, .vuecal__cell-events-count {
    background-color: #42b983;
  }
  .vuecal__title-bar {
    background-image: linear-gradient(225deg, #e94dd5, #651eae) !important;
    border-radius: 50px;
    .vuecal__title button {
        font-size: 1em;
        font-weight: 700;
        color: #fff !important;
    }
  }
  .vuecal__arrow {
    color: #fff;
  }
  .vuecal__event-title {
    margin-top: 3px;
    font-size: 0.8em;
    font-weight: 700;
    color: #fff;
    cursor: auto !important;
  }
  .vuecal__event-title--edit {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M442 150l-39 39-80-80 39-39q6-6 15-6t15 6l50 50q6 6 6 15t-6 15zM64 368l236-236 80 80-236 236H64v-80z" fill="%23fff" opacity=".6"/></svg>') !important;
    background-repeat: no-repeat;
    &:focus {
      background-color: #ffffff3b !important;
    }
}
  .vuecal__cell--today, .vuecal__cell--current {
    background-color: rgba(240, 240, 255, 0.4);
  }
  .vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
    background-color: rgba(235, 255, 245, 0.4);
  }
  .vuecal__cell--selected:before {
    border-color: rgba(66, 185, 131, 0.5);
  }
  .vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
  .vuecal__cell-split--highlighted {
    background-color: rgba(195, 255, 225, 0.5);
  }
  .vuecal__arrow.vuecal__arrow--highlighted,
  .vuecal__view-btn.vuecal__view-btn--highlighted {
    background-color: rgba(136, 236, 191, 0.25);
  }
}
</style>