import CandidateVideos from '@/services/CandidateVideos';

export default {
  async getAll({ commit }) {
    commit('setCandidateVideosLoading', true);
    try {
      const candidateVideos = await CandidateVideos.getAll();
      commit('setCandidateVideos', candidateVideos);
    } catch (error) {
      commit('setCandidateVideosErrors', error?.response?.data?.errors);
    }
    commit('setCandidateVideosLoading', false);
  },
  async create({ commit }, data) {
    commit('setCandidateVideosCreateLoading', true);
    try {
      await CandidateVideos.create(data.video);
      data.onSuccess();
      commit('setCandidateVideosCreateErrors', []);
    } catch (error) {
      commit('setCandidateVideosCreateErrors', error?.response?.data?.errors);
    }
    commit('setCandidateVideosCreateLoading', false);
  },
  async update({ commit }, data) {
    commit('setCandidateVideosUpdateLoading', true);
    try {
      await CandidateVideos.update(data);
      data.onSuccess();
      commit('setCandidateVideosUpdateErrors', []);
    } catch (error) {
      commit('setCandidateVideosUpdateErrors', error?.response?.data?.errors);
    }
    commit('setCandidateVideosUpdateLoading', false);
  },
  async verifyVideo({ commit }, data) {
    commit('setApplicationDetailLoading', true);
    try {
      await CandidateVideos.verifyVideo(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setApplicationDetailErrors', error?.response?.data?.errors);
    }
    commit('setApplicationDetailLoading', false);
  },
  async refuseVideo({ commit }, data) {
    commit('setApplicationDetailLoading', true);
    try {
      await CandidateVideos.refuseVideo(data);
      data.onSuccess();
    } catch (error) {
      commit('setApplicationDetailErrors', error?.response?.data?.errors);
    }
    commit('setApplicationDetailLoading', false);
  },
};
