<template>
  <div class="p-3 p-md-5 p-sm-3 min-vh-100 d-flex flex-column">
    <div class="row">
      <div class="col col-sm-12">
        <div class="mb-4">
          <router-link :to="{ name: 'Home' }">
            <img
              src="@/assets/images/icons/arrow-left.svg"
              alt="Retour à l'accueil"
              class="me-2"
            >
            <span>Retour à l'accueil</span>
          </router-link>
        </div>
        <h1 class="lh-1">
          <span class="text-medium">Créer</span>
          <br> <span class="text-bold">un compte</span>
        </h1>
      </div>
    </div>
    <choice
      v-if="step === 1"
      @choice="setChoice($event)"
    />
    <infos
      v-if="step === 2"
      @send-infos="setInfos($event)"
    />
    <end v-if="step === 3" />
  </div>
</template>

<script setup>
import Choice from '@/components/register/Choice.vue';
import Infos from '@/components/register/Infos.vue';
import End from '@/components/register/End.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const store = useStore();

const formData = ref({
  choice: route.params.profil,
});

const step = ref(1);

const setChoice = (choice) => {
  choice === 'company'
    ? router.push({ name: 'RegisterProfil', params: { profil: 'company' } })
    : router.push({ name: 'RegisterProfil', params: { profil: 'candidate' } });
  step.value = 2;
};

const setInfos = async (data) => {
  await store.dispatch('auth/register', {
    data,
    onSuccess: () => {
      step.value = 3;
    },
  });
};

onMounted(() => {
  if (route.params.profil) {
    step.value = 2;
  }
});

</script>
