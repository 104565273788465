<template>
  <div class="w-100 my-5 mb-lg-3">
    <h4 class="text-primary text-bold">
      Informations de mon entreprise
    </h4>
    <hr class="w-100">
    <div
      v-if="profilLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div
        class="row g-4"
      >
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
          <label class="form-label text-purple_grey text-bold">Nom de l'entreprise</label>
          <div class="w-100 ">
            {{ formData.company_name }}
          </div>
        </div>
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3 ">
          <label class="form-label text-purple_grey text-bold">Siret</label>
          <div class="w-100 ">
            {{ formData.siret }}
          </div>
        </div>
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
          <label class="form-label text-purple_grey text-bold">N° TVA intracommunautaire <required /></label>
          <div class="input-group has-validation">
            <input
              v-model="formData.tva_number"
              :class="[{ 'invalid' : profilErrors?.tva_number }, 'form-control', 'w-100']"
              placeholder="N° TVA intracommunautaire"
              required
            >
            <span
              v-if="profilErrors?.tva_number"
              class="text-danger"
            >{{ profilErrors?.tva_number[0] }}</span>
          </div>
        </div>
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
          <label class="form-label text-purple_grey text-bold">Effectif <required /></label>
          <div class="input-group has-validation">
            <select
              v-model="formData.company_size_id"
              :class="[{ 'invalid' : profilErrors?.company_size }, 'form-select', 'w-100']"
            >
              <option
                v-for="(company_size,index) in companySizes"
                :key="index"
                :value="company_size.id"
              >
                {{ companySizeLabel(company_size) }}
              </option>
            </select>
            <span
              v-if="profilErrors?.company_size_id"
              class="text-danger"
            >{{ profilErrors?.company_size_id[0] }}</span>
          </div>
        </div>
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
          <label class="form-label text-purple_grey text-bold">Secteur d'activité <required /></label>
          <div class="input-group has-validation">
            <select
              v-model="formData.company_sector_id"
              :class="[{ 'invalid' : profilErrors?.company_sector }, 'form-select', 'w-100']"
            >
              <option
                v-for="(company_sector,index) in companySectors"
                :key="index"
                :value="company_sector.id"
              >
                {{ company_sector.name }}
              </option>
            </select>
            <span
              v-if="profilErrors?.company_sector_id"
              class="text-danger"
            >{{ profilErrors?.company_sector_id[0] }}</span>
          </div>
        </div>
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
          <label class="form-label text-purple_grey text-bold">Nom du groupe</label>
          <div class="input-group has-validation">
            <input
              v-model="formData.group_name"
              :class="[{ 'invalid' : profilErrors?.group_name }, 'form-control', 'w-100']"
              placeholder="Nom du groupe"
            >
            <span
              v-if="profilErrors?.group_name"
              class="text-danger"
            >{{ profilErrors?.group_name[0] }}</span>
          </div>
        </div>
        <div class="col col-12 col-md-6 col-sm-12 col-lg-6 ">
          <label class="form-label text-purple_grey text-bold">Description de l'entreprise <required /></label>
          <div class="input-group has-validation">
            <textarea
              v-model="formData.description"
              :class="[{ 'invalid' : profilErrors?.description }, 'form-control', 'w-100']"
              placeholder="Description de l'entreprise (500 caractères max)"
              maxlength="500"
            />
            <span
              v-if="profilErrors?.description"
              class="text-danger"
            >{{ profilErrors?.description[0] }}</span>
          </div>
        </div>
      </div>
      <div class="row g-4 mt-3">
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
          <label class="form-label text-purple_grey text-bold">Télécharger votre logo <required /></label>
          <div
            v-if="!formData.logo"
            class="input-group has-validation"
          >
            <input
              :class="[{ 'invalid' : profilErrors?.logo }, 'form-control', 'w-100', 'btn-purple-gradient text-bold']"
              type="file"
              @change="changeLogo"
            >
            <span
              v-if="profilErrors?.logo"
              class="text-danger"
            >{{ profilErrors?.logo[0] }}</span>
          </div>
          <div
            v-else
            class="d-flex"
          >
            <div class="logo-company">
              <img
                :src="`${VITE_API_URL}/${formData.logo}`"
                alt="Logo de l'entreprise"
              >
            </div>
            <button
              type="button"
              class="btn-close btn-close-white ms-2"
              @click="removeLogo"
            />
          </div>
        </div>
        <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
          <label class="form-label text-purple_grey text-bold">
            Télécharger votre vidéo
          </label>
          <div
            v-if="!formData.video"
            class="input-group has-validation"
          >
            <input
              :class="[{ 'invalid' : profilErrors?.video }, 'form-control', 'w-100', 'btn-purple-gradient text-bold']"
              type="file"
              @change="changeVideo"
            >
            <span
              v-if="profilErrors?.video"
              class="text-danger"
            >{{ profilErrors?.video[0] }}</span>
          </div>
          <div
            v-else
            class="d-flex"
          >
            <div class="video-company-profile">
              <video controls>
                <source
                  :src="`${VITE_API_URL}/${formData.video}`"
                  type="video/mp4"
                >
              </video>
            </div>
            <button
              type="button"
              class="btn-close btn-close-white ms-2"
              @click="removeVideo"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row g-4">
      <div class="row d-flex justify-content-end align-items-center mt-5">
        <span
          v-if="success"
          class=" col-auto text-green mt-2"
        >Votre profil a bien été mis à jour !</span>
        <div class="col-auto mt-3">
          <button
            class="btn btn-outline-danger"
            @click="restore"
          >
            Annuler
          </button>
        </div>
        <div class="col-auto mt-3">
          <button
            v-if="profile?.data?.data?.profile_status === 'to_fill'"
            class="btn btn-purple-gradient text-bold"
            @click="update"
          >
            Sauvegarder
          </button>
          <button
            v-else
            class="btn btn-purple-gradient text-bold"
            @click="update"
          >
            Modifier
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3 me-3">
      <router-link
        class="text-end text-primary fst-italic"
        :to="{ name: 'CompanyOffersList' }"
      >
        <span class="redirect-link">
          Aller vers mes recrutements
          <img
            src="@/assets/images/icons/chevron-right-primary.svg"
            alt="Flêche"
          >
        </span>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Required from '@/components/Required.vue';

const store = useStore();
const profilErrors = computed(() => store.state.company.company_detail_errors);
const profilLoading = computed(() => store.state.company.company_detail_loading);
const profile = computed(() => ({
  ...store.state.company.company_detail,
}));
const companySizes = computed(() => store?.state?.companySizes?.company_sizes?.data?.data);
const companySectors = computed(() => store?.state?.companySector?.company_sector?.data?.data);

const { VITE_API_URL } = import.meta.env;

const formData = ref({
  company_name: '',
  siret: '',
  tva_number: null,
  company_sector_id: null,
  company_size_id: null,
  description: null,
  group_name: '',
  logo: null,
  video: null,
});

const restore = () => {
  store.dispatch('company/showProfile');
  formData.value = profile.value.data.data;
  formData.value.company_size_id = profile.value?.data?.data?.company_size?.id;
  formData.value.company_sector_id = profile.value?.data?.data?.company_sector?.id;
};

const logo = ref(null);
const video = ref(null);

const emit = defineEmits(['sendNotifVerification']);

watch(profile, (value) => {
  if (value) {
    formData.value.company_name = value.data.data.company_name ? value.data.data.company_name : '';
    formData.value.siret = value.data.data.siret;
    formData.value.logo = value.data.data.logo;
    formData.value.video = value.data.data.video;
    formData.value.tva_number = value.data.data.tva_number ? value.data.data.tva_number : '';
    formData.value.description = value.data.data.description ? value.data.data.description : '';
    formData.value.group_name = value.data.data.group_name ? value.data.data.group_name : '';
    formData.value.company_size_id = value?.data?.data?.company_size?.id;
    formData.value.company_sector_id = value?.data?.data?.company_sector?.id;
    emit('sendNotifVerification', value.data.data['verification-notifications']);
  }
});

const success = ref(false);

const update = () => {
  const tempFormData = { ...formData.value };
  if (tempFormData.logo) {
    tempFormData.logo = tempFormData.logo.replace('/storage', 'public');
  } else {
    delete tempFormData.logo;
  }
  if (tempFormData.video) {
    tempFormData.video = tempFormData.video.replace('/storage', 'public');
  } else {
    delete tempFormData.video;
  }
  const fd = new FormData();
  if (logo.value) {
    fd.append('logo', logo.value);
  }
  if (video.value) {
    fd.append('video', video.value);
  }
  // eslint-disable-next-line no-restricted-syntax
  Object.keys(tempFormData).forEach((key, index) => {
    fd.append(`${key}`, tempFormData[key]);
  });
  store.dispatch('company/update', {
    data: fd,
    onSuccess: () => {
      store.dispatch('company/showProfile');
      formData.value = profile.value.data.data;
      formData.value.company_size_id = profile.value?.data?.data?.company_size?.id;
      formData.value.company_sector_id = profile.value?.data?.data?.company_sector?.id;
      logo.value = null;
      video.value = null;
      success.value = true;
    },
  });
};

const changeLogo = (event) => {
  logo.value = event.target.files[0];
};

const removeLogo = () => {
  formData.value.logo = null;
  logo.value = null;
};

const removeVideo = () => {
  formData.value.video = null;
  video.value = null;
};
const changeVideo = (event) => {
  video.value = event.target.files[0];
};

const companySizeLabel = (companySize) => {
  if (companySize.minimum === null) {
    return `-${companySize.maximum}`;
  }
  if (companySize.maximum === null) {
    return `+${companySize.minimum}`;
  }
  return `${companySize.minimum}-${companySize.maximum}`;
};

onMounted(() => {
  store.dispatch('company/showProfile');
  store.dispatch('companySector/getAll');
  store.dispatch('companySizes/getAll');
  success.value = false;
});

</script>
