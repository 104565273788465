<template>
  <div class="d-flex justify-content-end align-item-end">
    <!--  notification top navigation-->
    <div
      v-if="!isAdminRoute"
      class="d-flex justify-content-end align-item-end"
    >
      <div
        v-if="!notificationActive"
        class="me-2 p-4 z-index1 position-fixed"
        @click="fetchNotifications(true)"
      >
        <a href="#">
          <img
            class="text-light image-icon"
            :src="notificationIcon?.icon"
            :alt="notificationIcon?.title"
          >
          <div
            v-show="hasNewNotification.length"
            class="z-index2 notification-badge"
          >
            <span class="notification-text text-bold">{{ hasNewNotification.length }}</span>
          </div>
        </a>
      </div>
      <div
        v-if="notificationActive"
        class="d-flex pt-4 z-index2 position-fixed notification-list"
      >
        <ul class="nav flex-column align-items-center">
          <li class="nav-item d-inline-flex z-index4">
            <div class="bg-purple-gradient d-inline-flex justify-content-center">
              <img
                class="text-light text-bold text-white align-self-center ps-3"
                :src="notificationIcon.icon"
              >
              <p class="text-bold text-white ps-4 pt-3 pr-60">
                {{ notificationIcon.title }}
              </p>
              <div
                class="nav-link z-index2 bg-light"
                @click="fetchNotifications(false)"
              >
                <a
                  href="#"
                  class="nav-link px-1"
                >
                  <img
                    :src="chevronRight"
                  > </a>
              </div>
            </div>
          </li>
          <li class=" z-index2 notification-card">
            <div v-if="notificationList.length > 0">
              <div
                v-for="notify in notificationList"
                :key="notify.id"
                :class="[notify.isNew ? 'bg-primary': 'bg-dark_grey','nav-item','d-inline-flex',
                         'align-items-center', 'justify-content-center', 'notification-layout',
                         notify.isLink ? 'notify-nav': 'bg-dark_grey']"
              >
                <router-link
                  :to="`/${notify.linkUrl}`"
                  replace
                >
                  <div class="d-inline-flex align-items-center w-100">
                    <img
                      class="text-light text-bold text-white align-self-left px-2"
                      :src="notifyIcon(notify.type)"
                    >
                    <div class="ps-2 pt-1 align-self-start">
                      <div class="text-bold text-truncate">
                        {{ notify.title }}
                      </div>
                      <div class="notification-description">
                        {{ notify.description }}
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div
              v-else
              :class="['bg-dark_grey','nav-item','d-inline-flex','align-items-center',
                       'justify-content-center', 'notification-layout']"
            >
              <p class="pt-3">
                Pas de nouvelle notification
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-else
      class="me-2 p-4 z-index2 position-fixed"
    >
      <div class="row w-100">
        <div
          v-if="VITE_ALLOW_TESTING_COMMANDS"
          class="col col-6 d-flex flex-column text-center"
        >
          <button
            class="btn btn-outline-light text-bold"
            @click="runNewMessage"
          >
            <span>Messages non lus</span>
            <span
              v-if="newMessageLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          </button>
          <div
            :class="newMessageApiResponse==='Succès !' ?
              'text-success text-bold' : 'text-danger text-bold'"
          >
            {{ newMessageApiResponse }}
          </div>
        </div>
        <div class="col d-flex flex-column text-center">
          <button
            class="btn btn-outline-light text-bold"
            @click="runMatching"
          >
            Lancer le moteur
            <span
              v-if="matchingLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          </button>
          <div
            :class="matchingApiResponse==='Succès !' ?
              'text-success text-bold' : 'text-danger text-bold'"
          >
            {{ matchingApiResponse }}
          </div>
        </div>
      </div>
      <div class="row" />
    </div>
  </div>
</template>

<script setup>
import {
  computed, ref, watch, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import iconNotify from '@/assets/images/icons/icon-notify.svg';
import chevronRight from '@/assets/images/icons/chevron-right.svg';
import iconValidate from '@/assets/images/icons/icon-validate.svg';
import iconBlock from '@/assets/images/icons/icon-block.svg';
import iconChat from '@/assets/images/icons/chat.svg';
import Api from '@/services/Api';

const { VITE_ALLOW_TESTING_COMMANDS } = import.meta.env;
const store = useStore();
const router = useRouter();
const profil = localStorage.getItem('scope');
const notificationActive = ref(false);
const isAdminRoute = ref(false);
const matchingApiResponse = ref('');
const matchingLoading = ref(false);
const newMessageApiResponse = ref('');
const newMessageLoading = ref(false);
const notificationList = ref([]);
const hasNewNotification = ref([]);
const notifications = computed(() => store.state.notification.notifications);

const notificationItem = {
  icon: iconNotify,
  title: 'Notifications',
};

const notificationIcon = computed(() => {
  if (profil === 'recruiter') {
    return notificationItem;
  } if (profil === 'user') {
    return notificationItem;
  }
  return null;
});
watch(notifications, (value) => {
  if (value) {
    notificationList.value = value.data.data;
    hasNewNotification.value = notificationList.value.filter((item) => item.isNew === true);
  }
});

const fetchNotifications = async (value) => {
  notificationActive.value = value;
  await store.dispatch('notification/getAll', {});
  if (value) {
    await store.dispatch('notification/markAsRead', {});
  }
};

const notifyIcon = (type) => {
  let icon = iconValidate;
  switch (type) {
    case 'verified':
    case 'up_accepted':
    case 'cp_accepted':
      icon = iconValidate;
      break;
    case 'refusé':
    case 'up_refused':
    case 'cp_refused':
    case 'rm_refused':
    case 'uv_refused':
    case 'mv_refused':
      icon = iconBlock;
      break;
    case 'deactivated':
      icon = iconBlock;
      break;
    case 'r_unread_messages':
      icon = iconChat;
      break;
    case 'u_unread_messages':
      icon = iconChat;
      break;
    default:
      icon = iconValidate;
      break;
  }
  return icon;
};

onMounted(() => {
  if (router?.currentRoute?.value?.meta?.scope === 'admin') {
    isAdminRoute.value = true;
    return;
  }
  store.dispatch('notification/getAll', {});
});

const runMatching = async () => {
  try {
    matchingLoading.value = true;
    await Api.post('api/matching-engine');
    matchingApiResponse.value = 'Succès !';
    matchingLoading.value = false;
    setTimeout(() => matchingApiResponse.value = '', 2000);
  } catch (e) {
    matchingApiResponse.value = 'Echec...';
    matchingLoading.value = false;
    setTimeout(() => matchingApiResponse.value = '', 2000);
  }
};

const runNewMessage = async () => {
  try {
    newMessageLoading.value = true;
    await Api.post('api/unread-messages');
    newMessageApiResponse.value = 'Succès !';
    newMessageLoading.value = false;
    setTimeout(() => newMessageApiResponse.value = '', 2000);
  } catch (e) {
    newMessageApiResponse.value = 'Echec...';
    newMessageLoading.value = false;
    setTimeout(() => newMessageApiResponse.value = '', 2000);
  }
};

</script>
