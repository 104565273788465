export default {
  setMatchingEngines(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matchingengines = value;
  },
  setMatchingEnginesErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matchingengines_errors = value;
  },
  setMatchingEnginesLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.matchingengines_loading = value;
  },
  setCreateMatchingEngine(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.create_matchingengine = value;
  },
  setCreateMatchingEngineErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.create_matchingengine_errors = value;
  },
  setCreateMatchingEngineLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.create_matchingengine_loading = value;
  },
};
