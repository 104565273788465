<template>
  <div>
    <button
      v-if="type === 'button'"
      class="btn btn-purple-gradient text-bold w-100"
      data-bs-toggle="modal"
      data-bs-target=".calendar-modal"
    >
      Fixer un RDV
    </button>
    <modal
      id="calendar-modal"
      class="calendar-modal"
      :title="'Fixer un RDV'"
      :action-button="'Confirmer'"
      @cancel-callback="handleCancel"
      @callback="sendEvents"
    >
      <calendar
        ref="myCalendar"
        :candidate="candidate"
        :recruiter="recruiter"
      />
    </modal>
  </div>
</template>

<script setup>
import Modal from '@/components/modal/Modal.vue';
import Calendar from '@/components/calendar/CalendarComponent.vue';
import { defineExpose, ref, onMounted, defineProps } from 'vue';
import { Modal as BSModal } from 'bootstrap';
import { useStore } from 'vuex';

const props = defineProps({
  type: { type: String, default: '' },
  candidate: Object,
  recruiter: Object,
});

const myCalendar = ref(null);

const showModal = () => {
  let calendarModal = document.getElementById('calendar-modal');
  let BSCalendarModal = new BSModal(calendarModal);
  BSCalendarModal.show();
};


const sendEvents = () => {
  // console.log('sendEvents', myCalendar.value);
  myCalendar.value.sendEvents();
};

const handleCancel = () => {
  myCalendar.value.cancelEventCreation();
};

defineExpose({
  showModal,
});

</script>

<style lang="scss">
#calendar-modal {
  .modal-dialog {
    max-width: 70% !important;
  }
}
</style>
<style lang="scss">
#calendar-modal {
  .modal-dialog {
    max-width: 70% !important;
  }
}
</style>