<template>
  <div
    class="mb-5 bg-dark_grey rounded-3 p-1 p-sm-5 mt-5
  d-flex flex-column align-items-center justify-content-center text-center row"
  >
    <div class="p-4 col col-12 col-sm-6 w-100">
      <h2>{{ title }}</h2>
      <p
        class="video-page-text"
        v-html="text"
      />
    </div>
    <div
      class="col col-12 col-sm-8 video-section rounded-3 overflow-hidden
    d-flex justify-content-center align-items-center p-0 flex-column "
    >
      <video
        v-if="video"
        controls
        class="w-100"
        :src="`${VITE_API_URL}/${video}`"
        style="max-height: 550px; background-color: black"
      />
      <record-viewer
        ref="recordViewer"
        :key="key"
        :update-mode="!!video"
        :success-message="successMessage"
        :loading="loading"
        @record-output="processRecordOutput"
      />
      <button
        v-if="recording"
        class="btn btn-light text-bold d-flex
        justify-content-center align-items-center mt-2"
        @click="stopRecording"
      >
        Retour
      </button>
      <p
        v-if="!recording"
        class="mt-2 mb-0"
      >
        OU
      </p>
      <video-import-modal
        v-if="!recording"
        :id="type"
        ref="videoImportModal"
        :update-mode="!!video"
        :success-import-message="successImportMessage"
        :update-errors="videoUploadUpdateErrors"
        :create-errors="videoUploadCreateErrors"
        :loading="loading"
        @send-video="sendVideo"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import RecordViewer from '@/components/record/RecordViewer.vue';
import VideoImportModal from '@/components/modal/VideoImportModal.vue';

const store = useStore();
const props = defineProps({
  text: String,
  title: String,
  video: String,
  type: String,
  id: Number,
});
const { VITE_API_URL } = import.meta.env;

const toModify = ref(false);
const newVideo = ref();
const successMessage = ref(false);
const successImportMessage = ref(false);

const videoImportModal = ref();
const recordViewer = ref();
const key = ref(1);
const loading = ref(false);

const recording = computed(() => recordViewer?.value?.recording);

const emit = defineEmits(['on-change-video']);

const videoUploadUpdateErrors = computed(() => store.state.candidateVideos.candidate_videos_update_errors);
const videoUploadCreateErrors = computed(() => store.state.candidateVideos.candidate_videos_errors);

const processRecordOutput = (blob) => {
  props.video ? updateVideo(blob) : uploadVideo(blob);
};

const sendVideo = (blob) => {
  props.video ? updateVideo(blob, true) : uploadVideo(blob, true);
};

const uploadVideo = (blob, fromModal = false) => {
  loading.value = true;
  successImportMessage.value = false;
  const file = new File([blob], 'record');
  const formData = new FormData();
  formData.append('video', file);
  formData.append('type', props.type);
  formData.append('status', 'to_validate');
  store.dispatch('candidateVideos/create', {
    video: formData,
    onSuccess: () => {
      loading.value = false;
      emit('on-change-video');
      toModify.value = false;
      store.dispatch('candidate/getCompletionProgress');
      if (fromModal) {
        videoImportModal.value.hideCollapse();
        successImportMessage.value = true;
      } else {
        successMessage.value = true;
      }
    },
  });
};

const updateVideo = (blob, fromModal = false) => {
  loading.value = true;
  successImportMessage.value = false;
  const file = new File([blob], 'record');
  const formData = new FormData();
  formData.append('video', file);
  formData.append('type', props.type);
  formData.append('status', 'to_validate');
  store.dispatch('candidateVideos/update', {
    id: props.id,
    data: formData,
    onSuccess: () => {
      loading.value = false;
      emit('on-change-video');
      toModify.value = false;
      store.dispatch('candidate/getCompletionProgress');
      if (fromModal) {
        videoImportModal.value.hideCollapse();
        successImportMessage.value = true;
      } else {
        successMessage.value = true;
      }
    },
  });
};

const stopRecording = () => {
  successMessage.value = false;
  key.value += 1;
};

</script>
