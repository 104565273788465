import Offer from '@/services/Offer';

export default {
  async getAll({ commit }, filter) {
    commit('setOffersLoading', true);
    try {
      const offers = await Offer.getAll(filter);
      commit('setOffers', offers);
      commit('setOffersErrors', []);
    } catch (error) {
      commit('setOffersErrors', error?.response?.data?.errors);
    }
    commit('setOffersLoading', false);
  },
  async getAllNotVerified({ commit }, filter) {
    commit('setOffersLoading', true);
    try {
      const offers = await Offer.getAllNotVerified(filter);
      commit('setOffers', offers);
      commit('setOffersErrors', []);
    } catch (error) {
      commit('setOffersErrors', error?.response?.data?.errors);
    }
    commit('setOffersLoading', false);
  },
  async getOne({ commit }, id) {
    commit('setOfferDetailLoading', true);
    try {
      const data = await Offer.getOne(id);
      if (data?.data?.data?.languages) {
        const languages = data.data.data.languages;
        data.data.data.languages = languages.filter(l => !l.code.includes('fr'));
      }
      commit('setOfferDetail', data);
      commit('setOfferDetailErrors', []);
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
  async create({ commit }, form) {
    commit('setOfferDetailLoading', true);
    try {
      await Offer.create(form.data);
      form.onSuccess();
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
  async update({ commit }, form) {
    commit('setOfferDetailLoading', true);
    try {
      await Offer.update(form);
      form.onSuccess();
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
  async verify({ commit }, data) {
    commit('setOfferDetailLoading', true);
    try {
      await Offer.verify(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
  async refuse({ commit }, data) {
    commit('setOfferDetailLoading', true);
    try {
      await Offer.refuse(data);
      data.onSuccess();
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
  async archive({ commit }, data) {
    commit('setOfferDetailLoading', true);
    try {
      await Offer.archive(data);
      data.onSuccess();
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
  async deactivate({ commit }, data) {
    commit('setOfferDetailLoading', true);
    try {
      await Offer.deactivate(data.id);
      data.onSuccess();
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
  async delete({ commit }, data) {
    commit('setOfferDetailLoading', true);
    try {
      await Offer.delete();
      data.onSuccess();
    } catch (error) {
      commit('setOfferDetailErrors', error?.response?.data?.errors);
    }
    commit('setOfferDetailLoading', false);
  },
};
