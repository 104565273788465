<template>
  <div>
    <h2>Liste des tutoriels vidéos</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les tutoriels vidéos
        </li>
      </ol>
    </nav>
    <div class="d-flex justify-content-end">
      <router-link :to="{ name: 'BackofficeTutorialCreate' }">
        <div class="btn btn-purple-gradient text-bold">
          Nouveau tutoriel
        </div>
      </router-link>
    </div>
    <ul
      id="myTab"
      class="nav nav-pills"
      role="tablist"
    >
      <li
        class="nav-item"
      >
        <button
          id="recruiter-tuto-tab"
          class="nav-link active show"
          data-bs-toggle="tab"
          data-bs-target="#recruiter-tuto"
          type="button"
          role="tab"
          aria-selected="true"
          @click="recruiterTuto"
        >
          <span>
            Tutoriels entreprise
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
      >
        <button
          id="candidate-tuto-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#candidate-tuto"
          type="button"
          role="tab"
          aria-selected="false"
          @click="candidateTuto"
        >
          <span>
            Tutoriels candidat
            <div class="line-primary" />
          </span>
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <div
        id="recruiter-tuto"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="recruiter-tuto-tab"
      >
        <div class=" d-flex flex-wrap">
          <div
            v-for="video in tutorials"
            :key="video.id"
            class="rounded overflow-hidden bg-dark_grey m-3"
            style="width: 400px"
          >
            <video
              controls
              class="w-100"
            >
              <source
                :src="`${VITE_API_URL}/${video?.video}`"
                type="video/mp4"
              >
            </video>
            <router-link :to="{ name: 'BackofficeTutorialDetail', params : { id : video.id } }">
              <div class="text-center p-2">
                <div class="text-bold card-text-lg text-uppercase">
                  {{ video.title }}
                </div>
                <div class="">
                  Type : {{ video.type === 'candidate' ? 'Candidat' : 'Recruteur' }}
                </div>
                <div>
                  Position : {{ video?.position }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div
        id="candidate-tuto"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="candidate-tuto-tab"
      >
        <div class=" d-flex flex-wrap">
          <div
            v-for="video in tutorials"
            :key="video.id"
            class="rounded overflow-hidden bg-dark_grey m-3"
            style="width: 400px"
          >
            <video
              controls
              class="w-100"
            >
              <source
                :src="`${VITE_API_URL}/${video?.video}`"
                type="video/mp4"
              >
            </video>
            <router-link :to="{ name: 'BackofficeTutorialDetail', params : { id : video?.id } }">
              <div class="text-center p-2">
                <div class="text-center p-2">
                  <div class="text-bold card-text-lg text-uppercase">
                    {{ video?.title }}
                  </div>
                  <div class="">
                    type : {{ video.type === 'candidate' ? 'Candidat' : 'Recruteur' }}
                  </div>
                  <div>
                    Position : {{ video?.position }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <pagination
      :meta="tutorialsPaginate"
      @change-page="changePage($event)"
    />
  </div>
</template>

<script setup>
import {
  computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import Pagination from '@/components/pagination/Pagination.vue';

const store = useStore();
// const filter = ref();
const { VITE_API_URL } = import.meta.env;

const tutorials = computed(() => store.state?.tutorial?.tutorials?.data?.data);
const tutorialsPaginate = computed(() => store.state?.tutorial?.tutorials?.data?.meta);

const changePage = (p) => {
  store.dispatch('tutorial/getAll', { page: p });
};

const recruiterTuto = () => {
  store.dispatch('tutorial/getAll', { page: 1, type: 'recruiter' });
};
const candidateTuto = () => {
  store.dispatch('tutorial/getAll', { page: 1, type: 'candidate' });
};

onMounted(() => {
  store.dispatch('tutorial/getAll', { page: 1, type: 'recruiter' });
});
</script>
