import Api from './Api';

export default {
  async getAll(filter) {
    return Api.get('api/offers', { params: filter });
  },
  async getAllNotVerified(filter) {
    return Api.get('api/offers', { params: { ...filter, unverified: true } });
  },
  async getOne(id) {
    return Api.get(`api/offers/${id}`);
  },
  async create(data) {
    return Api.post('api/offers/create', data);
  },
  async update(data) {
    return Api.post(`api/offers/${data.id}/update`, data.data);
  },
  async delete() {
    return Api.delete('api/offers/archived/delete');
  },
  async verify(id) {
    return Api.post(`api/offers/${id}/verify`);
  },
  async refuse(data) {
    return Api.post(`api/offers/${data.id}/refuse`, { reasons: data.description });
  },
  async archive(data) {
    return Api.post(`api/offers/${data.id}/archive`);
  },
};
