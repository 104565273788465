<template>
  <div class="p-3 p-md-5 p-sm-3 min-vh-100">
    <div class="row mt-5 text-center vh-70">
      <div class="m-auto">
        <modal
          id="meeting-modal"
          ref="meetingModal"
          title="Meeting"
          cancel-button="Décliner"
          action-button="Accepter"
          :show-actions="meeting?.status === 'new'"
          :backdrop-is-static="true"
          @cancel-callback="confirmMeeting('canceled')"
          @callback="confirmMeeting('approved')"
        >
          <template #header>
            <h5 class="modal-title">
              Meeting
            </h5>
            <button
              type="button"
              class="btn-close bg-light"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="redirect"
            />
          </template>
          <div v-if="meeting">
            <div>
              <img
                v-if="meeting?.status === 'new'"
                class="mx-3"
                src="@/assets/images/icons/info-circle-fill.svg"
                alt=""
              >
              <span v-if="scope === 'user'">
                <span class="text-bold"> {{ meeting.recruiter?.first_name }} {{ meeting.recruiter?.second_name }}</span> de
                <span class="text-bold"> {{ meeting.recruiter?.company?.name }} </span>
                vous a invité à participer à un entretien le <br><br>
              </span>
              <span v-if="scope === 'recruiter'">
                Vous avez invité <span class="text-bold"> {{ meeting.user?.first_name }} {{ meeting.user?.second_name }}</span>
                à participer à un entretien le <br><br>
              </span>
              <span class="text-bold"> {{ dayjs(meeting.start_date).format('DD/MM/YYYY') }} </span>
              de <span class="text-bold"> {{ dayjs(meeting.start_date).format('HH:MM') }} </span>
              à <span class="text-bold"> {{ dayjs(meeting.end_date).format('HH:MM') }} </span><br><br>
            </div>
            <div
              v-if="showDeclineReasonInput && scope === 'user' && meeting?.status === 'new'"
              class="text-start"
            >
              <label class="col-form-label">Motif du refus:</label>
              <textarea
                v-model="declineReason"
                type="text"
                class="form-control"
                placeholder="Vous pouvez proposer une autre date ici."
              />
            </div>
            <div v-if="meeting?.status === 'approved'">
              <img
                class="me-3"
                src="@/assets/images/icons/icon-validate.svg"
                alt=""
              >
              <span>{{ scope === 'user' ? 'Vous avez accepté cet entretien.' : 'L\'invitation a été acceptée' }}</span>
            </div>
            <div v-if="meeting?.status === 'canceled'">
              <img
                class="me-3"
                src="@/assets/images/icons/icon-block.svg"
                alt=""
              >
              <span>{{ scope === 'user' ? 'Vous avez refusé cet entretien.' : 'L\'invitation a été refusée' }}</span>
              <span v-if="meeting?.cancellation_reason"> pour la raison suivante : <br> {{ meeting?.cancellation_reason }}</span>
              <span />
            </div>
            <span
              v-if="isUpdatingRequestStatus"
              class="spinner-border text-center spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            />
          </div>
          <div v-else>
            <img
              class="mx-3"
              src="@/assets/images/icons/info-circle-fill.svg"
              alt=""
            >
            <span class=""> Cette invitation n'existe plus... </span>
          </div>
          <template #footer>
            <button
              type="button"
              class="btn btn-outline-danger text-bold"
              @click="handleDecline"
            >
              Décliner
            </button>
            <button
              type="button"
              class="btn btn-purple-gradient text-bold"
              @click="confirmMeeting('approved', '')"
            >
              Accepter
            </button>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Modal from '@/components/modal/Modal.vue';
import { Modal as BSModal } from 'bootstrap';
import dayjs from 'dayjs';
import normalizer from '@/utils/normalizer';


const store = useStore();
const router = useRouter();
const route = useRoute();

const meeting = computed(() => store.state.meeting?.meeting_detail?.data);
const meetingModal = ref(null);


const isUpdatingRequestStatus = ref(false);

const confirmMeeting = async (status, cancellation_reason) => {
  isUpdatingRequestStatus.value = true;
  let data = {
    status,
  };
  if (cancellation_reason) {
    data.cancellation_reason = cancellation_reason;
  }
  const form = normalizer.makeFormDataWithObject(data);

  await store.dispatch('meeting/confirm', { id: route.params.meetingId, form });
  await store.dispatch("meeting/getOne", route.params.meetingId);
  isUpdatingRequestStatus.value = false;
};


const showDeclineReasonInput = ref(false);
const declineReason = ref(null);

const handleDecline = () => {
  if (!showDeclineReasonInput.value) {
    showDeclineReasonInput.value = true;
    return;
  }

  showDeclineReasonInput.value = false;

  confirmMeeting('canceled', declineReason.value || 'no reason provided');
};


const showModal = () => {
  let meetingModalElement = document.getElementById('meeting-modal');
  meetingModal.value = new BSModal(meetingModalElement);
  meetingModal.value.show();
};

const scope = computed(() =>
  route.meta.scope,
);

const redirect = async () => {
  console.log('redirect');
  router.push({ name: scope.value === "recruiter" ? "CompanyOffersList" : "CandidateDashboard" });
};

onMounted(async () => {

  await store.dispatch("meeting/getOne", route.params.meetingId);

  if (route.meta.scope === 'candidate') {
     // if I am logged in as a recruiter go to not-authorized page
    if (store.getters['auth/user'].data?.company || scope.value === "company") {
      return router.push({ name: 'not-authorize' });
    }
    // if I am logged in as another candidate than the one of the meeting request go to not-authorized page
    if (store.state.meeting.meeting_detail_errors === "This action is unauthorized.") {
      return router.push({ name: 'not-authorize' });
    }

    if (route.query.action && meeting.value.status === 'new') {
      if (route.query.action === 'accept') {
        await confirmMeeting('approved');
      } else {
        showDeclineReasonInput.value = true;
      }
    }
  }
  showModal();
});

</script>
