<template>
  <div>
    <div
      class="d-flex flex-column bg-dark_grey p-3 rounded-3
     mb-5 border border-2 border-primary stats-dashboard"
    >
      <h1>
        Statistiques
      </h1>
      <hr>
      <div class="d-flex pb-3 flex-wrap w-100 justify-content-around ">
        <div class="text-center">
          <span class="statNumber text-primary text-bold">{{ dashboardData?.statistics?.active_offers }}</span>
          <h5>Nombre d'offres actives</h5>
        </div>
        <div class="text-center">
          <span class="statNumber text-primary text-bold">
            {{ dashboardData?.statistics?.unseen_matchings }}</span>
          <h5>Nombre de candidatures <br> non visionnées</h5>
        </div>
        <div class="text-center">
          <span class="statNumber text-primary text-bold">
            {{ dashboardData?.statistics?.untreated_matchings }}</span>
          <h5>Nombre de candidatures <br> non traitées</h5>
        </div>
        <div class="text-center">
          <span class="statNumber text-primary text-bold">
            {{ dashboardData?.statistics?.received_matchings }}</span>
          <h5>Nombre total <br> de candidatures reçues</h5>
        </div>
      </div>
    </div>
    <h1 class="m-2">
      <b>Mes recrutements</b>
    </h1>
    <hr>
    <div class="d-flex align-items-center flex-wrap">
      <div class="me-5 mb-3 mb-sm-0">
        <span
          v-if="offersErrors?.to"
          class="text-danger"
        >{{ offersErrors?.to[0] }}</span>
        <datepicker
          v-model="formData.filter.dateRange"
          :range="true"
          lang="fr"
          :circle="true"
          :show-clear-button="true"
          placeholder="Sélectionnez des dates"
          @reset="formData.filter.dateRange = []"
        />
      </div>
      <div class="me-5 mb-3 mb-sm-0">
        Filtrer par :
      </div>
      <div class="me-5 mb-3 mb-sm-0">
        <div
          v-if="formData.filter.s_date === 'desc'"
          class="cursor-pointer"
          @click="formData.filter.s_date = 'asc';
                  formData.order_filter.s_date = true; formData.order_filter.matches = false"
        >
          <img

            v-if="formData.order_filter.s_date"
            src="@/assets/images/icons/arrow-up.svg"
            alt=""
          >
          Date de création
        </div>
        <div
          v-else
          class="cursor-pointer"
          @click="formData.filter.s_date = 'desc';
                  formData.order_filter.s_date = true; formData.order_filter.matches = false"
        >
          <img
            v-if="formData.order_filter.s_date"
            src="@/assets/images/icons/arrow-down.svg"
            alt=""
          >
          Date de création
        </div>
      </div>
      <div class="me-5 mb-3 mb-sm-0">
        <div
          v-if="formData.filter.matches === 'desc'"
          class="cursor-pointer"
          @click="formData.filter.matches = 'asc';
                  formData.order_filter.s_date = false; formData.order_filter.matches = true"
        >
          <img
            v-if="formData.order_filter.matches "
            src="@/assets/images/icons/arrow-up.svg"
            alt=""
          >
          Nombre de candidats
        </div>
        <div
          v-else
          class="cursor-pointer"
          @click="formData.filter.matches = 'desc';
                  formData.order_filter.s_date = false; formData.order_filter.matches = true"
        >
          <img
            v-if="formData.order_filter.matches "
            src="@/assets/images/icons/arrow-down.svg"
            alt=""
          >
          Nombre de candidats
        </div>
      </div>
      <div class=" mb-3 mb-sm-0">
        <div class="form-check form-switch">
          <label class="form-check-label ms-2">
            <span>
              Mes recrutements uniquement
            </span>
          </label>
          <input
            v-model="formData.filter.owned"
            class="form-check-input custom-switch"
            type="checkbox"
          >
        </div>
      </div>
    </div>

    <hr>
    <div class="w-100 text-end mb-5">
      <button
        v-show="formData?.filter?.state === 'archived' && offers?.[0]"
        :disabled="(formData?.filter?.state === 'archived' && !offers?.[0])
          || (formData?.filter?.state !== 'archived')"
        class="btn btn-outline-danger mx-2 text-bold"
        @click="deleteArchivedOffers"
      >
        Supprimer les recrutements archivées
      </button>
      <router-link
        :to="{ name: 'CompanyOfferCreate' }"
      >
        <button class="btn btn-purple-gradient text-bold">
          Publier une offre
        </button>
      </router-link>
    </div>
    <ul
      id="myTab"
      class="nav nav-pills "
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="all-offers-tab"
          class="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#all-offers"
          type="button"
          role="tab"
          aria-controls="all-offers"
          aria-selected="true"
          @click="formData.filter.state = 'active'; formData.filter.verified = null; formData.filter.status = null"
        >
          <span>
            Tous les recrutements
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="active-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#active-offer"
          type="button"
          role="tab"
          aria-controls="active-offer"
          aria-selected="true"
          @click="formData.filter.state = 'active'; formData.filter.verified = true"
        >
          <span>
            Recrutements actifs
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="inactive-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#inactive-offer"
          type="button"
          role="tab"
          aria-controls="inactive-offer"
          aria-selected="false"
          @click="formData.filter.state = 'inactive'; formData.filter.verified = null"
        >
          <span>
            Recrutements inactifs
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="archived-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#archived-offer"
          type="button"
          role="tab"
          aria-controls="archived-offer"
          aria-selected="false"
          @click="formData.filter.state = 'archived'"
        >
          <span>
            Recrutements archivés
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="pending-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#pending-offer"
          type="button"
          role="tab"
          aria-controls="pending-offer"
          aria-selected="false"
          @click="
            formData.filter.status = 'to_validate'; formData.filter.state = null; formData.filter.verified = false
          "
        >
          <span>
            Recrutements en attente de validation
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="refused-offer-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#refused-offer"
          type="button"
          role="tab"
          aria-controls="refused-offer"
          aria-selected="false"
          @click="formData.filter.status = 'refused'; formData.filter.state = null; formData.filter.verified = null"
        >
          <span>
            Recrutements refusés
            <div class="line-primary" />
          </span>
        </button>
      </li>
    </ul>
    <div
      v-if="offersLoading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
    >
      <div
        v-if="offers?.[0]"
        class="tab-content mb-5 mb-sm-0"
      >
        <div
          id="all-offers"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="all-offers-tab"
        >
          <offers-list :list="offers" />
        </div>
        <div
          id="active-offer"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="active-offer-tab"
        >
          <offers-list :list="offers" />
        </div>
        <div
          id="inactive-offer"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="inactive-offer-tab"
        >
          <offers-list :list="offers" />
        </div>
        <div
          id="archived-offer"
          class="tab-pane fade show"
          role="tabpanel"
          aria-labelledby="archived-offer-tab"
        >
          <offers-list :list="offers" />
        </div>
        <div
          id="pending-offer"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="pending-offer-tab"
        >
          <offers-list :list="offers" />
        </div>
      </div>

      <div
        v-else
        class=" d-flex justify-content-center mt-5"
      >
        <h5>Pas encore d'offre...</h5>
      </div>
    </div>
  </div>
</template>

<script setup>

import {
  computed, onMounted, ref, watch,
} from 'vue';
import OffersList from '@/components/offers/OffersList.vue';
import { useStore } from 'vuex';

const store = useStore();
const dashboardData = computed(() => store.state.recruiterDashboard?.stats?.data?.data);

const formData = ref({
  my_offer: false,
  filter: {
    dateRange: [],
    status: null,
    state: '',
    owned: false,
    s_date: 'asc',
    matches: 'desc',
    verified: null,
  },
  order_filter: {
    s_date: true,
    matches: false,
  },
});
const offers = computed(() => (store.state.offer?.offers?.data?.data));
const offersLoading = computed(() => (store.state.offer?.offers_loading));
const offersErrors = computed(() => (store.state.offer?.offers_errors));

watch(formData.value.filter, () => {
  const tempFilters = ref({ ...formData.value.filter });
  delete tempFilters.value.dateRange;
  if (formData.value.filter?.dateRange?.[1]) {
    tempFilters.value.from = null;
    tempFilters.value.to = null;
    tempFilters.value.from = formData.value.filter.dateRange[0];
    tempFilters.value.to = formData.value.filter.dateRange[1];
  }
  tempFilters.value.state ? delete tempFilters.value.status : delete tempFilters.value.state;
  tempFilters.value.owned === false ? tempFilters.value.owned = 0 : tempFilters.value.owned = 1;
  formData.value.order_filter.s_date
    ? delete tempFilters.value.matches : delete tempFilters.value.s_date;
  store.dispatch('offer/getAll', tempFilters.value);
  refreshStats(tempFilters.value.owned);
});

const deleteArchivedOffersSuccess = ref(false);

const deleteArchivedOffers = () => {
  store.dispatch('offer/delete', {
    onSuccess: () => {
      deleteArchivedOffersSuccess.value = true;
      store.dispatch('offer/getAll', { state: 'archived' });
    },
  });
};

const getAllOffers = () => {
  store.dispatch('offer/getAll');
};

watch(offers, (value) => {
  if (value) {
    deleteArchivedOffersSuccess.value = false;
  }
});

const refreshStats = async (mineOnly = false) => {
  store.dispatch('recruiterDashboard/getAll', { showAll: mineOnly ? 0 : 1 });
};

onMounted(() => {
  store.dispatch('offer/getAll',{ state: 'active' });
  refreshStats();
});

</script>
