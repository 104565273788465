import Api from './Api';

export default {
  async getAll(filter) {
    return Api.get('api/meetings', { params: filter });
  },
  async getOne(id) {
    return Api.get(`api/meetings/${id}`);
  },
  async confirm(id, data) {
    return Api.post(`api/meetings/${id}/confirm`, data);
  },
  async create(data) {
    return Api.post('api/meetings', data);
  },
  async update(data) {
    return Api.post(`api/meetings/${data.id}/update`, data.data);
  },
};