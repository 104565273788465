<template>
  <div>
    <h2>Détails du candidat</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•'"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les candidats
        </li>
      </ol>
    </nav>
    <div class="my-4">
      <router-link :to="{ name: 'CandidatesList' }">
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour aux candidats"
          class="me-2"
        >
        <span>Retour aux candidats</span>
      </router-link>
    </div>
    <div class="d-flex justify-content-between align-items-end mt-5">
      <h1>{{ candidate?.first_name }} {{ candidate?.second_name }}</h1>
      <candidate-badge
        :status="{
          verified: status?.user_verified,
          deactivated: status?.user_deactivated,
          profile_status: status?.profile_status,
          matching_video: status?.matching_video_status === 'to_validate',
        }"
      />
    </div>
    <hr>
    <div class="d-flex justify-content-between flex-sm-column">
      <div class="d-flex justify-content-end flex-wrap">
        <button
          v-if="toValidateStatuses.includes(status?.profile_status)"
          class="btn btn-green text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".validate-modal"
          :disabled="candidateLoading"
        >
          <span
            v-if="candidateLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Valider le compte
        </button>
        <button
          v-if="toValidateStatuses.includes(status?.profile_status)"
          class="btn btn-orange text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".refuse-modal"
          :disabled="candidateLoading"
        >
          <span
            v-if="candidateLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Refuser le compte
        </button>
        <button
          v-if="!status?.user_deactivated"
          class="btn btn-outline-danger text-bold mx-2"
          data-bs-toggle="modal"
          data-bs-target=".deactivate-modal"
          :disabled="candidateLoading"
        >
          <span
            v-if="candidateLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Désactiver le compte
        </button>
      </div>
      <modal
        class="validate-modal"
        :title="'Validation de compte'"
        :text="'Etes-vous sûr de vouloir valider ce compte candidat ?'"
        :action-button="'Valider'"
        @callback="validateAccount"
      />
      <modal
        class="refuse-modal"
        :title="'Refus de compte'"
        :text="'Veuillez décrire le motif du refus'"
        :have-input="true"
        :action-button="'Refuser'"
        @callback="refuseAccount($event)"
      />
      <modal
        class="deactivate-modal"
        :title="'Desactivation de compte'"
        :text="'Etes-vous sûr de vouloir desactiver ce compte candidat ?'"
        :action-button="'Desactiver'"
        @callback="deactivateAccount"
      />
    </div>
    <ul
      id="myTab"
      class="nav nav-pills"
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="candidate-infos-tab"
          class="nav-link active"
          data-bs-toggle="tab"
          data-bs-target="#candidate-infos"
          type="button"
          role="tab"
          aria-controls="candidate-infos"
          aria-selected="true"
        >
          <span>
            Les informations
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="videos-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#candidate-videos"
          type="button"
          role="tab"
          aria-controls="videos"
          aria-selected="false"
        >
          <span>
            Les vidéos
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="videos-applications-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#candidate-videos-applications"
          type="button"
          role="tab"
          aria-controls="videos-applications-applications"
          aria-selected="false"
        >
          <span>
            Les vidéos de motivations
            <div class="line-primary" />
          </span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="documents-tab"
          class="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#candidate-documents"
          type="button"
          role="tab"
          aria-controls="andidate-documents"
          aria-selected="false"
        >
          <span>
            Les documents
            <div class="line-primary" />
          </span>
        </button>
      </li>
    </ul>
    <div
      id="myTabContent"
      class="tab-content"
    >
      <div
        id="candidate-infos"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="profil-tab"
      >
        <candidate-detail-infos
          :profile="candidate"
          :loading="candidateLoading"
        />
      </div>
      <div
        id="candidate-videos"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="account-tab"
      >
        <candidate-detail-videos
          :videos="videos"
          :loading="candidateLoading"
          :type="'presentation'"
        />
      </div>
      <div
        id="candidate-videos-applications"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="account-tab"
      >
        <candidate-detail-videos
          :videos="applicationsVideos"
          :loading="candidateLoading"
          :type="'motivation'"
        />
      </div>
      <div
        id="candidate-documents"
        class="tab-pane fade"
        role="tabpanel"
        aria-labelledby="account-tab"
      >
        <candidate-detail-documents
          :documents="documents"
          :loading="candidateLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CandidateDetails",
};
</script>

<script setup>
import { computed, onMounted } from "vue";
import Modal from "@/components/modal/Modal.vue";
import CandidateDetailVideos from "@/components/backoffice/candidateDetailVideos.vue";
import CandidateDetailDocuments from "@/components/backoffice/candidateDetailDocuments.vue";
import CandidateBadge from "@/components/badge/CandidateBadge.vue";

import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CandidateDetailInfos from "@/components/backoffice/candidateDetailInfos.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();

const candidate = computed(() =>
  store.state.candidate.candidate_detail?.data?.data?.child_profile
    ? store.state.candidate.candidate_detail?.data?.data?.child_profile
    : store.state.candidate.candidate_detail?.data?.data?.profile,
);

const candidateLoading = computed(
  () => store.state.candidate.candidate_detail_loading,
);

const status = computed(
  () => store.state.candidate.candidate_detail?.data?.data?.status,
);

const videos = computed(
  () => store.state.candidate.candidate_detail?.data?.data?.videos,
);

const applicationsVideos = computed(
  () => store.state.candidate.candidate_detail?.data?.data?.matching_videos,
);
const childDocuments = computed(
  () => store.state.candidate.candidate_detail?.data?.data?.child_profile?.user_documents.map(d => { d.isFromChild = true; return d;}),
);
const parentDocuments = computed(
  () => store.state.candidate.candidate_detail?.data?.data?.profile?.user_documents.map(d => { d.isFromParent = true; return d;}),
);
const documents = computed(
  () => {
    let mergedDocumentArrays= [
      ...(childDocuments.value?? []), ...(parentDocuments.value?? []),
    ];
    return mergedDocumentArrays;
  },
);

const validateAccount = () => {
  store.dispatch("candidate/verify", {
    id: route.params.id,
    onSuccess: () => {
      router.push({ name: "CandidatesList" });
    },
  });
};

const refuseAccount = (value) => {
  store.dispatch("candidate/refuse", {
    id: route.params.id,
    description: value,
    onSuccess: () => {
      router.push({ name: "CandidatesList" });
    },
  });
};
const deactivateAccount = () => {
  store.dispatch("candidate/deactivate", {
    id: route.params.id,
    onSuccess: () => {
      router.push({ name: "CandidatesList" });
    },
  });
};

const toValidateStatuses = [
  "to_validate",
  "profile_to_validate",
  "videos_to_validate",
];

onMounted(() => {
  store.dispatch("candidate/getOne", route.params.id);
});
</script>
