export default {
  hasVerifiedEmail(state) {
    return !!state.user?.data?.email_verified_at;
  },
  authenticated(state) {
    return !!state.user?.data;
  },
  user(state) {
    return state.user;
  },
  authenticationErrors(state) {
    return state.authentication_errors;
  },
  authenticationLoading(state) {
    return state.authentication_loading;
  },
};
