<template>
  <div>
    <h2>Liste des entreprises</h2>
    <nav
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les entreprises
        </li>
      </ol>
    </nav>
    <counter-badge
      :value="companiesCount"
      unit="entreprise(s)"
      text="inscrite(s) sur la plateforme"
      badge-state="success"
    />
    <counter-badge
      v-if="companiesNotVerifiedCount"
      :value="companiesNotVerifiedCount"
      unit="entreprise(s)"
      text="à valider"
      badge-state="info"
    />
    <div>
      <button
        class="btn btn-light mb-5"
        @click="toggleAll"
      >
        {{ displayAll ? 'Afficher les entreprises à valider uniquement' :
          'Afficher toutes les entreprises' }}
      </button>
    </div>
    <div class="form-group row mb-2">
      <label class="form-label text-purple_grey text-bold col-1">Rechercher :
      </label>
      <input
        type="text"
        class="col-3"
        @input="dosearch"
      >
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            Nom
          </th>
          <th scope="col">
            Utilisateur principal
          </th>
          <th scope="col">
            Date de création du compte
          </th>
          <th scope="col">
            Statut d'activation
          </th>
          <th scope="col">
            Détail
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="company in companies"
          :key="company.id"
          class="align-middle"
        >
          <td>{{ company?.company_name }}</td>
          <td>{{ company?.first_recruiter_name }}</td>
          <td>
            {{
              normalizer.formatDate(company?.company_creation_date)
            }}
          </td>
          <td>
            <badge
              :status="
                { verified : company?.company_verified,
                  deactivated : company?.company_deactivated,
                  profile_status : company?.profile_status, }"
            />
          </td>
          <td>
            <router-link
              :to="{ name: 'CompanyDetail',
                     params: { id : company?.company_id } }"
            >
              <button class="btn btn-purple-gradient text-bold">
                Voir
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :meta="companiesPaginate"
      @change-page="changePage($event)"
    />
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import normalizer from '@/utils/normalizer';
import Badge from '@/components/badge/Badge.vue';
import CounterBadge from '@/components/badge/CounterBadge.vue';
import Pagination from '@/components/pagination/Pagination.vue';

const store = useStore();
const companiesCount = ref(0);
const companiesNotVerifiedCount =ref(0);
const showTable = ref(false);

const companies = computed(() => store.state?.company?.companies?.data?.data);
const companiesPaginate = computed(() => store.state?.company?.companies?.data?.meta);
const displayAll = ref(false);

const changePage = (p) => {
  if (displayAll.value) {
    store.dispatch('company/getAll', { page: p });
  } else {
    store.dispatch('company/getAllNotVerified', { page: p });
  }
};

const toggleAll = () => {
  displayAll.value = !displayAll.value;
  if (displayAll.value) {
    store.dispatch('company/getAll', { page: 1 });
  } else {
    store.dispatch('company/getAllNotVerified', { page: 1 });
  }
};

const dosearch = (search) => {
  store.dispatch("company/getAll", {
    page: 1,
    search: search.target.value,
  });
};
onMounted(() => {
  store.dispatch('company/getAll')
  .then(() => {
    companiesCount.value = store.state?.company?.companies?.data?.meta?.total;
    store.dispatch('company/getAllNotVerified')
      .then(() => {
        companiesNotVerifiedCount.value = store.state?.company?.companies?.data?.meta?.total;
        showTable.value = true;
      });
  });
});
</script>
