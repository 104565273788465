<template>
  <div>
    <h2>Liste des offres</h2>
    <nav
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les offres
        </li>
      </ol>
    </nav>
    <counter-badge
      :value="offersCount"
      unit="offre(s)"
      text="active(s) sur la plateforme"
      badge-state="success"
    />
    <counter-badge
      v-if="offersNotVerifiedCount"
      :value="offersNotVerifiedCount"
      unit="offres(s)"
      text="à valider"
      badge-state="info"
    />
    <br>
    <button
      class="btn btn-light mb-5"
      @click="toggleAll"
    >
      {{ displayAll ? 'Afficher les offres à valider uniquement' :
        'Afficher toutes les offres' }}
    </button>
    <table
      v-if="showTable"
      class="table table-striped table-hover"
    >
      <thead>
        <tr>
          <th scope="col">
            Intitulé
          </th>
          <th scope="col">
            Nom de l'entreprise
          </th>
          <th scope="col">
            Auteur
          </th>
          <th scope="col">
            Date de création
          </th>
          <th scope="col">
            Statut d'activation
          </th>
          <th scope="col">
            Détail
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="offer in offers"
          :key="offer.id"
          class="align-middle"
        >
          <td>{{ offer?.title }}</td>
          <td>{{ offer?.company_name }}</td>
          <td>{{ offer?.author.first_name + ' ' + offer?.author.second_name }}</td>
          <td>
            {{
              normalizer.formatDate(offer?.created_at)
            }}
          </td>
          <td>
            <badge
              :status="
                { verified : offer?.status?.offer_verified,
                  profile_status : offer?.status?.offer_status, }"
            />
          </td>
          <td>
            <router-link :to="{ name: 'BackofficeOfferDetail', params: { id : offer.id } }">
              <button class="btn btn-purple-gradient text-bold">
                Voir
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :meta="offersPaginate"
      @change-page="changePage($event)"
    />
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import normalizer from '@/utils/normalizer';
import Badge from '@/components/badge/Badge.vue';
import CounterBadge from '@/components/badge/CounterBadge.vue';
import Pagination from '@/components/pagination/Pagination.vue';

const store = useStore();
const offersCount = ref(0);
const offersNotVerifiedCount = ref(0);
const showTable = ref(false);
const offers = computed(() => store.state?.offer?.offers?.data?.data);
const offersPaginate = computed(() => store.state?.offer?.offers?.data?.meta);
const displayAll = ref(false);

const changePage = (p) => {
  if (displayAll.value) {
    store.dispatch('offer/getAll', { page: p });
  } else {
    store.dispatch('offer/getAllNotVerified', { page: p });
  }
};

const toggleAll = () => {
  displayAll.value = !displayAll.value;
  if (displayAll.value) {
    store.dispatch('offer/getAll', { page: 1 });
  } else {
    store.dispatch('offer/getAllNotVerified', { page: 1 });
  }
};

onMounted(() => {
  store.dispatch('offer/getAll', { state: 'active' })
    .then(() => {
      offersCount.value = store.state?.offer?.offers?.data?.meta?.total;
      store.dispatch('offer/getAllNotVerified', { page: 1 })
        .then(() => {
          offersNotVerifiedCount.value = store.state?.offer?.offers?.data?.meta?.total;
          showTable.value = true;
        });
    });
});
</script>
