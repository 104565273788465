<template>
  <div>
    <div class=" bg-red d-flex flex-column justify-content-center min-vh-100 mx-2">
      <navbar-content
        :key="key"
        class="vertical-navbar"
        :content="content"
      />
      <navbar-content-horizontal
        :key="key"
        :content="content"
      />
    </div>
  </div>
</template>

<script setup>
import NavbarContentHorizontal from '@/components/navbar/NavbarContentHorizontal.vue';
import {
  computed, ref, watch,
} from 'vue';
import NavbarContent from '@/components/navbar/NavbarContent.vue';
import house from '@/assets/images/icons/house.svg';
import briefcase from '@/assets/images/icons/briefcase.svg';
import chat from '@/assets/images/icons/chat.svg';
import video from '@/assets/images/icons/video.svg';
import person from '@/assets/images/icons/person-circle.svg';
import camera from '@/assets/images/icons/camera-video.svg';
import building from '@/assets/images/icons/building.svg';
import stopwatch from '@/assets/images/icons/stopwatch.png';
import workspace from '@/assets/images/icons/person-workspace.svg';
import { useStore } from 'vuex';

const profil = localStorage.getItem('scope');

const store = useStore();
const unreadMessages = computed(() => store.state.auth?.unread_messages);
const key = ref(1);

const navbarCompany = [
  {
    icon: briefcase,
    title: 'Recrutements',
    to: { name: 'CompanyOffersList' },
    navIcon: 'offers',
  },
  {
    icon: chat,
    title: 'Messagerie',
    to: { name: 'CompanyChat' },
    navIcon: 'chat',
    unread: unreadMessages.value,
  },
  {
    icon: video,
    title: 'Mes tutoriels',
    to: { name: 'CompanyTutorials' },
    navIcon: 'tutorials',
  },
  {
    icon: person,
    title: 'Mon entreprise',
    to: { name: 'CompanyProfil' },
    navIcon: 'profil',
  },
];
const navbarCandidate = [
  {
    icon: house,
    title: 'Dashboard',
    to: { name: 'CandidateDashboard' },
    navIcon: 'dashboard',
  },
  {
    icon: camera,
    title: 'Mes vidéos',
    to: { name: 'CandidateVideos' },
    navIcon: 'videos',
  },
  {
    icon: chat,
    title: 'Messagerie',
    to: { name: 'CandidateChat' },
    navIcon: 'chat',
    unread: unreadMessages.value,
  },
  {
    icon: video,
    title: 'Mes tutoriels',
    to: { name: 'CandidateTutorials' },
    navIcon: 'tutorials',
  },
  {
    icon: person,
    title: 'Mon profil',
    to: { name: 'CandidateProfil' },
    navIcon: 'profil',
  },
];
const navbarAdmin = [
  {
    icon: building,
    title: 'Les entreprises',
    to: { name: 'CompaniesList' },
    navIcon: 'companies',
  },
  {
    icon: stopwatch,
    title: 'Matching',
    to: { name: 'MatchingEngine' },
    navIcon: 'matchingengine',
  },
  {
    icon: person,
    title: 'Les candidats',
    to: { name: 'CandidatesList' },
    navIcon: 'candidates',
  },
  {
    icon: briefcase,
    title: 'Les offres',
    to: { name: 'BackofficeOffersList' },
    navIcon: 'offers',
  },
  {
    icon: video,
    title: 'Les tutoriels',
    to: { name: 'BackofficeTutorialsList' },
    navIcon: 'tutorials',
  },
  {
    icon: chat,
    title: 'Messagerie',
    to: { name: 'ChatList' },
    navIcon: 'chat',
  },
  {
    icon: workspace,
    title: 'Les administrateurs',
    to: { name: 'AdminsList' },
    navIcon: 'admin',
  },
];

const content = computed(() => {
  if (profil === 'recruiter') {
    return navbarCompany;
  } if (profil === 'user') {
    return navbarCandidate;
  }
  return navbarAdmin;
});

const updateNavBarContent = (navbar) => {
  navbar.forEach((link) => {
    // eslint-disable-next-line no-param-reassign
    link.unread = link.navIcon === 'chat' ? unreadMessages.value : null;
  });
};

watch(unreadMessages, () => {
  if (profil === 'recruiter') {
    updateNavBarContent(navbarCompany);
  } if (profil === 'user') {
    updateNavBarContent(navbarCandidate);
  }
  key.value += 1;
});

</script>
