<template>
  <div>
    <h2>
      Candidat :
      <span class="text-bold">
        {{
          profil?.user_profile?.first_name +
            " " +
            profil?.user_profile?.second_name
        }}
      </span>
    </h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•'"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'CompanyOffersList' }">
            Mes recrutements
          </router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Candidature
        </li>
      </ol>
    </nav>

    <div class="row">
      <div
        v-if="matchingVideo || profilVideos.length > 0"
        class="col col-12 col-lg-2"
      >
        <div class="d-flex w-100 justify-content-center mb-3">
          <span class="text-primary text-bold ms-1">VIDEOS</span>
        </div>
        <div
          v-if="matchingVideo"
          class="rounded overflow-hidden bg-primary mb-3"
        >
          <video
            controls
            class="w-100"
          >
            <source
              :src="`${VITE_API_URL}/${matchingVideo?.video}`"
              type="video/mp4"
            >
          </video>
          <div class="text-center p-2">
            <span class="text-bold">Motivation</span>
          </div>
        </div>
        <div
          v-for="video in profilVideos"
          :key="video.id"
          class="rounded overflow-hidden bg-dark_grey mb-3"
        >
          <video
            controls
            class="w-100"
          >
            <source
              :src="`${VITE_API_URL}/${video?.video}`"
              type="video/mp4"
            >
          </video>
          <div class="text-center p-2">
            <span class="text-bold">{{ videosTypes[video?.type] }}</span>
          </div>
        </div>
      </div>
      <div class="col bg-dark_grey rounded">
        <div class="row my-4">
          <div
            class="
              col col-12 col-sm-3
              p-4
              d-flex
              flex-column
              justify-content-center
            "
          >
            <img
              :src="`${VITE_API_URL}/${profil?.user_profile?.picture}`"
              alt="Photo du candidat"
              class="rounded-3 w-100"
            >
          </div>
          <div
            class="
              col col-12 col-sm-6
              p-4
              d-flex
              flex-column
              justify-content-center
            "
          >
            <h2 class="text-bold">
              {{
                profil?.user_profile?.first_name +
                  " " +
                  profil?.user_profile?.second_name
              }}
            </h2>
            <span>
              <span class="text-bold">Date de naissance :</span>
              {{ normalizer.formatDate(profil?.user_profile?.birth_date) }}
            </span>
            <span>
              <span class="text-bold">Localisation :</span>
              {{ profil?.user_profile?.city?.name }}
            </span>
            <span class="mt-3">
              <span class="text-bold"> Email : </span>
              <a :href="`mailto:${profil?.user_profile?.email}`">
                {{ profil?.user_profile?.email }}
              </a>
            </span>
            <span>
              <span class="text-bold"> Numéro de téléphone : </span>
              {{ profil?.user_profile?.phone_number }}
            </span>
            <span class="mt-3">
              <span class="text-bold">Langue(s) : </span>
              <span
                v-for="language in profil?.user_profile?.languages"
                :key="language.id"
              >{{ language.name + " " }}
              </span>
            </span>
            <span>
              <span class="text-bold"> RQTH : </span>
              {{ profil?.user_profile?.rqth ? "Oui": "Non" }}
            </span>
            <span>
              <span class="text-bold"> Permis obtenu(s) : </span>
              <span
                v-for="license in profil?.user_profile?.licenses"
                :key="license.id"
              >
                {{ license.name + " " }}
              </span>
              <span v-if="profil?.user_profile?.licenses.length <= 0">
                Aucun
              </span>
            </span>
            <span>
              <span class="text-bold"> Véhicule : </span>
              {{ profil?.user_profile?.has_vehicle == 1 ? "Oui": "Non" }}
            </span>
            <span
              v-if="profil?.user_profile?.external_links?.length"
              class="text-truncate-250 mt-3"
            ><span class="text-bold">Liens
               externes : </span>
              <span
                v-for="link in profil?.user_profile?.external_links"
                :key="link"
              >
                <br><a :href="link">{{ link }}</a>
              </span>
            </span>
            <span
              v-if="profil?.user_profile?.user_documents?.length"
              class="mt-3"
            ><span class="text-bold">Documents : </span>
              <span
                v-for="document in profil?.user_profile?.user_documents"
                :key="`document_${document.id}`"
                class="d-flex"
              >
                <span class="me-2"> {{ document.type }} : </span>
                <upload-document
                  :file="document"
                />
              </span>
            </span>
          </div>
          <div class="col col-12 col-sm-3 d-flex flex-column text-center">
            <span
              class="score-bar-progress-label"
              :style="[
                profil?.score > 75
                  ? { color: '#63c555' }
                  : [
                    profil?.score > 50
                      ? { color: '#ebfa74' }
                      : { color: '#f05745' },
                  ],
              ]"
            >MATCHING {{ profil?.score }}%</span>
            <div class="progress bg-white_grey rounded-pill mb-4">
              <div
                class="progress-bar"
                role="progressbar"
                :style="[
                  { width: profil?.score + '%' },
                  [
                    profil?.score > 75
                      ? { 'background-color': '#63c555' }
                      : [
                        profil?.score > 50
                          ? { 'background-color': '#ebfa74' }
                          : { 'background-color': '#f05745' },
                      ],
                  ],
                ]"
                :aria-valuenow="profil?.score"
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
            <div
              v-if="profil?.offer_state === 'inactive'"
              class="bg-danger text-light p-3 rounded-3 mb-3"
            >
              <span class="card-text-lg text-bold">
                L'offre a été désactivée
              </span>
            </div>
            <div
              v-else
              class="d-flex flex-column"
            >
              <div
                v-if="profil?.recruiter_step === 'recruited'"
                class="bg-green text-light p-3 rounded-3 mb-3"
              >
                <span class="card-text-lg text-bold"> Candidat recruté ! </span>
              </div>
              <div
                v-if="profil?.recruiter_step === 'refused'"
                class="bg-danger text-light p-3 rounded-3 mb-3"
              >
                <span class="card-text-lg text-bold"> Candidat refusé </span>
              </div>
              <div
                v-if="profil?.recruiter_step === 'accepted'"
                class="bg-blue text-light p-3 rounded-3 mb-3"
              >
                <span class="card-text-lg text-bold">
                  Candidat shortlisté
                </span>
              </div>
              <button
                v-if="
                  profil?.recruiter_step === 'new' ||
                    profil?.recruiter_step === 'refused'
                "
                class="
                  btn btn-outline-green
                  my-2
                  d-flex
                  align-items-center
                  justify-content-center
                "
                :disabled="profilLoading"
                @click="acceptCandidate"
              >
                <span
                  v-if="profilLoading"
                  class="spinner-border text-center spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                />
                <span>Shortlister la candidature</span>
              </button>
              <button
                v-if="profil?.recruiter_step === 'accepted'"
                class="
                  btn btn-outline-green
                  my-2
                  d-flex
                  justify-content-center
                  align-items-center
                "
                data-bs-toggle="modal"
                data-bs-target=".recruit-candidate-modal"
                :disabled="profilLoading"
              >
                <span
                  v-if="profilLoading"
                  class="spinner-border text-center spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                />
                <span>Recruter le candidat</span>
              </button>
              <modal
                class="modal recruit-candidate-modal"
                :title="'Désactivation de l\'offre'"
                :text="'Souhaitez-vous désactiver l\'offre suite au recrutement du candidat ?'"
                :action-button="'Désactiver l\'offre'"
                :cancel-button="'Ne pas désactiver l\'offre'"
                @callback="deactivateOfferStatus(true)"
                @cancel-callback="deactivateOfferStatus(false)"
              />
              <button
                v-if="
                  profil?.recruiter_step === 'new' ||
                    profil?.recruiter_step === 'accepted'
                "
                class="
                  btn btn-outline-danger
                  my-2
                  d-flex
                  justify-content-center
                "
                data-bs-toggle="modal"
                data-bs-target=".refuse-candidate-modal"
                :disabled="profilLoading"
              >
                <span
                  v-if="profilLoading"
                  class="spinner-border text-center spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                />
                <span>Refuser la candidature</span>
              </button>
              <modal
                class="modal refuse-candidate-modal"
                :title="'Refus du candidat'"
                :text="'Veuillez saisir un motif de refus de la candidature'"
                :have-input="true"
                :action-button="'Refuser la candidature'"
                @callback="refuseCandidate(declineReason)"
              >
                <div v-if="!isCustomEmail">
                  <label class="col-form-label">Motif du refus:</label>
                  <textarea
                    v-model="declineReason"
                    type="text"
                    class="form-control"
                    placeholder="Motif du refus"
                  />
                  <a
                    href="#"
                    @click.prevent="toggleEmailType"
                  >Utiliser un template</a>
                </div>
                <div v-else>
                  <label class="col-form-label">Bonjour {{ profil.user_profile.first_name }},</label>
                  <textarea
                    v-model="declineCustomEmailContent"
                    type="text"
                    class="form-control"
                    placeholder="Motif du refus"
                    rows="5"
                  />
                  <a
                    href="#"
                    @click.prevent="toggleEmailType"
                  >Mail automatique</a>
                </div>
              </modal>
              <button
                v-if="
                  profil?.recruiter_step === 'accepted' &&
                    me?.data?.id === profil?.recruiter_id
                "
                class="btn btn-purple-gradient my-2 text-bold"
                :loading="startNewChatLoading"
                @click="ChatCandidate"
              >
                Echanger sur la messagerie
              </button>
              <calendar-modal
                type="button"
                :candidate="profil?.user_profile"
                :recruiter="recruiter?.data"
              />
              <button
                class="btn btn-info my-2 text-bold"
                data-bs-toggle="modal"
                data-bs-target=".note-candidate-modal"
              >
                Bloc-note
              </button>
              <modal-notes
                class="modal note-candidate-modal"
                :title="'Bloc-note'"
                :have-input="true"
                :action-button="'Ajouter la note'"
                :notes="notes"
                :errors="notesErrors"
                :loading="notesLoading"
                :empty-input="emptyNoteInput"
                @callback="addNote($event)"
                @reset-empty-note-input="resetEmptyNoteInput"
              />
              <button
                class="btn btn-info my-2 text-bold d-none"
                @click="applicationDetailPDF.toPDF()"
              >
                Exporter en PDF
              </button>
              <modal
                id="application-error-modal"
                class="modal"
                title="Une erreur s'est produite :"
                :show-actions="false"
              >
                <img
                  class="me-3"
                  src="@/assets/images/icons/info-circle-fill.svg"
                  alt=""
                >
                <br><br>
                <p> {{ refuseCandidateError }} </p>
              </modal>
            </div>
          </div>
        </div>

        <div class="row mt-3 g-4 pb-4">
          <h4 class="text-primary text-bold">
            Sa recherche
          </h4>
          <hr>
          <span class="col col-4">
            <span class="text-bold text-white_grey">Métier </span> <br>
            <span
              v-for="job in profil?.user_research?.job"
              :key="job"
            >
              {{ job?.name + " " }}
            </span>
          </span>
          <span class="col col-4">
            <span class="text-bold text-white_grey">Nombre d'années d'expérience
            </span>
            <br>
            <span class="text-bold">{{
              profil?.user_research?.years_of_experience
            }}</span>
          </span>
          <span class="col col-4">
            <span class="text-bold text-white_grey">Type de contrat </span><br>
            <span
              v-for="type in profil?.user_research?.contract_types"
              :key="type.id"
              class="text-bold"
            >{{
              type.name +
                " "
            }}
            </span>
          </span>
          <span class="col col-4">
            <span class="text-bold text-white_grey">Fourchette de salaire</span><br>
            <span class="text-bold">
              {{
                profil?.user_research?.salary_minimum +
                  "€ - " +
                  profil?.user_research?.salary_maximum +
                  "€"
              }}
            </span>
          </span>
          <span class="col col-4">
            <span class="text-bold text-white_grey">Disponibilité </span><br>
            <span class="text-bold">
              {{
                profil?.user_research?.available_now
                  ? "Disponible maintenant"
                  : normalizer.formatDate(profil?.user_research?.availability)
              }}
            </span>
          </span>
        </div>
        <div
          v-if="profil?.user_profile?.cv"
          class="row mt-3 g-4 pb-1"
        >
          <h4 class="text-primary text-bold">
            Son curriculum vitae
          </h4>
          <hr>
          <iframe
            style="border: none;
              width: 100%;
              position: relative;
              transform: unset;
              min-height: 100vh;
            "
            :src="`${VITE_API_URL}/${profil?.user_profile?.cv}`"
          />
        </div>
      </div>
    </div>
    <!-- PDF TEMPLATE -->
    <application-detail-p-d-f
      ref="applicationDetailPDF"
      class="bg-dark_grey"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import normalizer from "@/utils/normalizer";
import Modal from "@/components/modal/Modal.vue";
import CalendarModal from "@/components/calendar/CalendarModal.vue";
import ModalNotes from "@/components/modal/ModalNotes.vue";
import RecommendationModal from "@/components/modal/RecommendationModal.vue";
import ApplicationDetailPDF from "@/containers/authenticated/company/ApplicationDetailPDF.vue";
import UploadDocument from "@/components/uploadDocument/UploadDocument.vue";
import MatchingService from "@/services/Matching";
import { Modal as BSModal } from 'bootstrap';


const route = useRoute();
const store = useStore();
const router = useRouter();

const applicationDetailPDF = ref();

const profil = computed(
  () => store.state.matching?.matching_detail?.data?.data,
);
const recruiter = computed(() => store.getters['auth/user']);
const profilLoading = computed(
  () => store.state.matching?.matching_detail_loading,
);
const matchingVideo = computed(
  () => store.state.matching?.matching_detail?.data?.data?.matching_video,
);
const profilVideos = computed(
  () => store.state.matching?.matching_detail?.data?.data?.videos ?? [],
);
const newChat = computed(() => store.state.matching?.chat.data);
const startNewChatLoading = computed(
  () => store.state.matching?.create_chat_loading,
);
const notes = computed(() => store.state.note?.notes?.data?.data);
const notesErrors = computed(() => store.state.note?.notes_errors);
const notesLoading = computed(() => store.state.note?.notes_loading);
const me = computed(() => JSON.parse(localStorage.getItem("user")));
const offer = computed(() => store.state.offer?.offer_detail?.data?.data);

const { VITE_API_URL } = import.meta.env;
const newChatCreated = ref(0);
const chatId = ref(null);
const declineReason = ref('');
const declineCustomEmailContent = ref('');
const isCustomEmail= ref(false);
const videosTypes = ref({
  video_presentation: "Présentation",
  video_experiences: "Expériences",
  video_courses: "Formations",
  video_hobbies: "Atouts",
});
const refuseCandidateError = ref('');

watch(newChat, (value) => {
  if (value.data?.id) {
    newChatCreated.value = 1;
    chatId.value = value.data?.id;
  }
});

const matchingErrors = computed(
  () => store.state.matching?.matching_detail_errors);


watch(matchingErrors, (value) => {
  if (
      value
      && Object.keys(value).length !== 0
      && value.reasons || value.custom_description
    ) {
      refuseCandidateError.value = (value.reasons[0] || value.custom_description[0]);
      showRefuseCandidateErrorModal();
    }
});

const showRefuseCandidateErrorModal = () => {
  let errorModal = document.getElementById('application-error-modal');
  let BSErrorModal = new BSModal(errorModal);
  console.log('refuseCandidateError', refuseCandidateError);
  BSErrorModal.show();
};

const acceptCandidate = () => {
  store.dispatch("matching/acceptCandidate", {
    matching_id: route.params.matching_id,
    offer_id: route.params.offer_id,
  });
};
const refuseCandidate = async (value) => {
  const data = {
    matching_id: route.params.matching_id,
    offer_id: route.params.offer_id,
  };
  if (value) {
    data.reason = value;
  } else if (declineCustomEmailContent.value) {
    data.custom_description = declineCustomEmailContent.value;
  }

  store.dispatch("matching/refuseCandidate", data);
  // try {
  //   const matching = await MatchingService.refuseCandidate(data);
  //   store.commit('setMatchingDetail', matching);
  // } catch (error) {
  //   store.commit('setMatchingDetailErrors', error?.response?.data?.errors);
  //   refuseCandidateError.value = error.response?.data?.errors?.reasons[0] || error.response?.data?.errors?.custom_description[0] || error.response?.data?.errors?.message || 'erreur';
  //   showRefuseCandidateErrorModal();
  // }
};


const toggleEmailType = () => {
  isCustomEmail.value = !isCustomEmail.value;
  if (isCustomEmail.value) {
    declineCustomEmailContent.value = computeDefaultCustomEmailTemplate(offer.value.title, declineReason.value);
  } else {
    declineCustomEmailContent.value = null;
  }
};

const computeDefaultCustomEmailTemplate = (offerTitle, declineReason, companyName = 'Viewzme') => {
  return `Je fais suite à votre candidature pour le poste de ${offerTitle}.
J'ai bien étudié votre profil et malheureusement, nous ne
pourrons pas donner suite pour les raisons suivantes :
${declineReason ?? '[raisons du refus]'}

Je vous souhaite une bonne continuation,

Cordialement,
${companyName}
`;
};

const deactivateOffer = ref(false);

const recruitCandidate = () => {
  store.dispatch("matching/recruitCandidate", {
    matching_id: route.params.matching_id,
    offer_id: route.params.offer_id,
    deactivate_offer: deactivateOffer.value,
  });
  store.dispatch("matching/getOne", {
    matching_id: route.params.matching_id,
    offer_id: route.params.offer_id,
  });
};

const deactivateOfferStatus = (value) => {
  deactivateOffer.value = value;
  recruitCandidate();
};

const emptyNoteInput = ref(false);

const resetEmptyNoteInput = () => {
  emptyNoteInput.value = false;
};

const addNote = (value) => {
  store.dispatch("note/create", {
    offer_id: route.params.offer_id,
    matching_id: route.params.matching_id,
    data: value,
    onSuccess: () => {
      store.dispatch("note/getAll", {
        offer_id: route.params.offer_id,
        matching_id: route.params.matching_id,
      });
      emptyNoteInput.value = true;
    },
  });
};

const ChatCandidate = async () => {
  await store.dispatch("matching/createChat", {
    matching_id: route.params.matching_id,
    offer_id: route.params.offer_id,
    user_id: profil.value?.user_profile?.id,
  });
  if (newChatCreated.value === 1) {
    router.push({ name: "CompanyChat", params: { roomId: chatId.value } });
  }
};

onMounted(() => {
  store.dispatch("matching/getOne", {
    matching_id: route.params.matching_id,
    offer_id: route.params.offer_id,
  });
  store.dispatch("note/getAll", {
    offer_id: route.params.offer_id,
    matching_id: route.params.matching_id,
  });
  store.dispatch("offer/getOne", route.params.offer_id);
});
</script>
<style scoped>
.col-form-label {
  width: 100%;
  text-align: left;
}
</style>