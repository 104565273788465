<template>
  <div class="mt-5">
    <h4 class="text-primary text-bold">
      Informations personnelles
    </h4>
    <hr class="w-100">
    <div
      v-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Photo de profil</label>
        <div class="photo-profile">
          <img
            :src="`${VITE_API_URL}/${profile?.picture}`"
            alt="Photo de profile"
          >
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="row g-4 mt-2"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Prénom</label>
        <p>{{ profile?.first_name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Nom</label>
        <p>{{ profile?.second_name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Date de naissance</label>
        <p>{{ normalizer.formatDate(profile?.birth_date) }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Téléphone</label>
        <p>{{ normalizer.formatPhoneNumber(profile?.phone_number) }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Adresse</label>
        <p>{{ profile?.address1 }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Complément d'adresse</label>
        <p>{{ profile?.address2 }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Ville</label>
        <p>{{ profile?.city?.name }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Code postal</label>
        <p>{{ profile?.city?.zip_code }}</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Permis</label>
        <p
          v-for="license in profile?.licenses"
          :key="license"
        >
          {{ license?.name + " " }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Langues</label>
        <p
          v-for="language in profile?.languages"
          :key="language"
        >
          {{ language?.name + " " }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">RQTH</label>
        <p>{{ profile?.rqth? "Oui": "Non" }}</p>
      </div>
      <div class="col col-12 col-sm-6">
        <label class="form-label text-purple_grey text-bold">Liens externes</label>
        <p
          v-for="link in profile?.external_links"
          :key="link"
        >
          <a
            :href="link"
            class="text-break"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ link }}
          </a>
          <br>
        </p>
        <p v-if="!profile?.external_links">
          Aucun lien
        </p>
      </div>
    </div>

    <h4 class="text-primary text-bold mt-5">
      Recherche
    </h4>
    <hr class="w-100">
    <div
      v-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Chargement...</span>
      </div>
    </div>
    <div
      v-else
      class="row g-4 mt-4"
    >
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">M"tier recherché</label>
        <div
          v-for="job in profile?.research?.job"
          :key="job"
        >
          {{ job?.name + " " }}
        </div>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Type de contrat</label>
        <div
          v-for="type in profile?.research?.contract_types"
          :key="type"
        >
          {{ type?.name + " " }}
        </div>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Télétravail</label>
        <p>
          {{ teleworkOptions?.[profile?.research?.telework] }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Niveau d'étude souhaité</label>
        <p>
          {{ profile?.research?.degreeLevel?.name }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Années d'expérience</label>
        <p>
          {{ profile?.research?.years_of_experience }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Salaire minimum</label>
        <p>{{ profile?.research?.salary_minimum }} €</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Salaire maximum</label>
        <p>{{ profile?.research?.salary_maximum }} €</p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Disponibilité</label>
        <p>
          {{
            profile?.research?.availability
              ? normalizer.formatDate(profile?.research?.availability)
              : "Disponible maintenant"
          }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Ville</label>
        <p>
          {{ profile?.research?.city?.name }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Code postal</label>
        <p>
          {{ profile?.research?.city?.zip_code }}
        </p>
      </div>
      <div class="col col-12 col-sm-3">
        <label class="form-label text-purple_grey text-bold">Rayon autour de la ville</label>
        <p>{{ profile?.research?.radius }} km</p>
      </div>
    </div>

    <h4 class="text-primary text-bold mt-5">
      Curriculum Vitae
    </h4>
    <hr class="w-100">
    <iframe
      v-if="profile?.cv && isPdf"
      style="
        border: none;
        width: 100%;
        position: relative;
        transform: unset;
        min-height: 100vh;
      "
      :src="`${VITE_API_URL}/${profile?.cv}`"
    />
    <!-- Otherwise show a button to download the PDF -->
    <button
      v-else-if="profile?.cv"
      class="btn btn-green text-bold"
    >
      <a
        :href="`${VITE_API_URL}/${profile?.cv}`"
        target="_blank"
        rel="noopener noreferrer"
        class="text-white"
      >
        Télécharger le CV
      </a>
    </button>
    <p v-else>
      Aucun CV
    </p>
  </div>
</template>
<script setup>
import normalizer from "@/utils/normalizer";
import { computed } from "vue";

const { VITE_API_URL } = import.meta.env;

const teleworkOptions = {
  none: "Pas de télétravail",
  partial: "Télétravail partiel",
  full: "100% télétravail",
};

const props = defineProps({
  profile: Object,
  loading: Boolean,
});

// Computed property to determine if the cv is a pdf. If it is, return true, otherwise return false
const isPdf = computed(() => {
  if (props.profile?.cv) {
    const cv = props.profile.cv;
    const extension = cv.split(".").pop();
    return extension === "pdf";
  }
  return false;
});
</script>
