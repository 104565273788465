<template>
  <div
    class="badge mt-1 mb-2 me-2"
    :class="classObject"
  >
    {{ value }} {{ unit }} <br> {{ text }}
  </div>
</template>
<script setup>
import { computed } from 'vue';

const classObject = computed(() => ({
  ['bg-green']: (props.badgeState === 'success'),
  ['bg-orange']: (props.badgeState === 'info'),
}));

const props = defineProps({
  value: Number,
  unit: String,
  text: String,
  badgeState: String,
});

</script>