<template>
  <div class="p-5">
    <div class="mb-4">
      <router-link :to="{ name: 'Home' }">
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour à l'accueil"
          class="me-2"
        >
        <span>Retour à l'accueil</span>
      </router-link>
    </div>
    <h1>Formulaire de contact</h1>
    <hr>
    <div class="row g-4">
      <div class="col col-6 ">
        <label class="form-label text-purple_grey text-bold">Prénom  <required /></label>
        <input
          v-model="formData.first_name"
          :class="[{ 'invalid' : contactErrors?.first_name }, 'form-control', 'w-100']"
          placeholder="Votre prénom"
          required
        >
        <span
          v-if="contactErrors?.first_name"
          class="text-danger"
        >{{ contactErrors?.first_name[0] }}</span>
      </div>
      <div class="col col-6">
        <label class="form-label text-purple_grey text-bold">Nom <required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.second_name"
            :class="[{ 'invalid' : contactErrors?.second_name }, 'form-control', 'w-100']"
            placeholder="Votre nom"
            required
          >
          <span
            v-if="contactErrors?.second_name"
            class="text-danger"
          >{{ contactErrors?.second_name[0] }}</span>
        </div>
      </div>
      <div class="col col-6">
        <label class="form-label text-purple_grey text-bold">Téléphone</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.phone_number"
            :class="[{ 'invalid' : contactErrors?.phone_number }, 'form-control', 'w-100']"
            placeholder="Votre numéro de téléphone"
            required
          >
          <span
            v-if="contactErrors?.second_name"
            class="text-danger"
          >{{ contactErrors?.second_name[0] }}</span>
        </div>
      </div>
      <div class="col col-6 ">
        <label class="form-label text-purple_grey text-bold">Email <required /></label>
        <input
          v-model="formData.email"
          type="email"
          :class="[{ 'invalid' : contactErrors?.email }, 'form-control', 'w-100']"
          placeholder="Votre adresse email"
          required
        >
        <span
          v-if="contactErrors?.email"
          class="text-danger"
        >{{ contactErrors?.email[0] }}</span>
      </div>
      <div class="col col-6 ">
        <label class="form-label text-purple_grey text-bold">Sujet <required /></label>
        <select
          v-model="formData.subject"
          :class="[{ 'invalid' : contactErrors?.subject }, 'form-control', 'w-100']"
          placeholder="Veuillez choisir un sujet"
          required
        >
          <option
            v-for="sub in subjects"
            :key="sub"
            :value="sub"
          >
            {{ sub }}
          </option>
          <span
            v-if="contactErrors?.subject"
            class="text-danger"
          >{{ contactErrors?.subject[0] }}</span>
        </select>
        <div
          v-if="formData.subject === 'Autre'"
          class="mt-2"
        >
          <input
            v-model="formData.other_subject"
            :class="[{ 'invalid' : contactErrors?.subject }, 'form-control', 'w-100']"
            placeholder="Sujet"
            required
          >
          <span
            v-if="contactErrors?.subject"
            class="text-danger"
          >{{ contactErrors?.subject[0] }}</span>
        </div>
      </div>
      <div class="col col-12 ">
        <label class="form-label text-purple_grey text-bold">Message <required /></label>
        <textarea
          v-model="formData.content"
          :class="[{ 'invalid' : contactErrors?.content }, 'form-control', 'w-100']"
          placeholder="Votre message"
          rows="5"
          required
        />
        <span
          v-if="contactErrors?.content"
          class="text-danger"
        >{{ contactErrors?.content[0] }}</span>
      </div>
    </div>
    <div class="row w-100 my-3">
      <div class="col justify-content-center d-flex">
        <button
          class="btn btn-purple-gradient text-bold p-3 mt-4 rounded-3"
          :disabled="contactLoading"
          type="submit"
          @click="sendMessage"
        >
          <span
            v-if="contactLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Envoyer le message
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Required from '@/components/Required.vue';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();
const props = defineProps({
  subject: String,
});

const contactErrors = computed(() => store.state?.contact?.contact_content_errors?.data);
const contactLoading = computed(() => store.state?.contact?.contact_content_loading?.data);

const formData = ref({
  first_name: '',
  second_name: '',
  subject: '',
  other_subject: '',
  phone_number: '',
  email: '',
  content: '',
});

const subjects = [
  "Changement d'abonnement",
  'Autre',
];

const sendMessage = () => {
  store.dispatch('contact/sendMessage', {
    first_name: formData.value.first_name,
    second_name: formData.value.second_name,
    subject: formData.value.other_subject ? formData.value.other_subject : formData.value.subject,
    phone_number: formData.value.phone_number,
    email: formData.value.email,
    content: formData.value.content,
  });
};

onMounted(() => {
  if (route.query.subject === 'changeSubscription') {
    formData.value.subject = 'Changement d\'abonnement';
  }
});
</script>
