import Meeting from '@/services/Meeting';

export default {
  async getAll({ commit }, data) {
    commit('setMeetingsLoading', true);
    try {
      const meetings = await Meeting.getAll(data);
      commit('setMeetings', meetings);
      commit('setMeetingsErrors', []);
    } catch (error) {
      commit('setMeetingsErrors', error?.response?.data?.errors);
    }
    commit('setMeetingsLoading', false);
  },
  async getOne({ commit }, data) {
    commit('setMeetingDetailLoading', true);
    try {
      const meeting = await Meeting.getOne(data);
      commit('setMeetingDetail', meeting);
      commit('setMeetingDetailErrors', []);
    } catch (error) {
      commit('setMeetingDetailErrors', error?.response?.data?.errors || error?.response?.data?.message);
    }
    commit('setMeetingDetailLoading', false);
  },
  async create({ commit }, form) {
    commit('setMeetingDetailLoading', true);
    try {
      await Meeting.create(form.data);
      form.onSuccess();
    } catch (error) {
      commit('setMeetingDetailErrors', error?.response?.data?.errors);
    }
    commit('setMeetingDetailLoading', false);
  },
  async confirm({ commit }, data) {
    commit('setMeetingDetailLoading', true);
    try {
      await Meeting.confirm(data?.id, data?.form);
      data?.form.onSuccess();
    } catch (error) {
      commit('setMeetingDetailErrors', error?.response?.data?.errors);
    }
    commit('setMeetingDetailLoading', false);
  },
  async update({ commit }, form) {
    commit('setMeetingDetailLoading', true);
    try {
      await Meeting.update(form);
      form.onSuccess();
    } catch (error) {
      commit('setMeetingDetailErrors', error?.response?.data?.errors);
    }
    commit('setMeetingDetailLoading', false);
  },
};
