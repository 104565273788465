<template>
  <router-view v-if="isReady" />
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();

store.dispatch("auth/checkAuthenticated");

const isReady = ref(false);

router.isReady().then(() => {
  isReady.value = true;
});

</script>
