<template>
  <div class="d-flex flex-wrap mt-5">
    <div
      v-if="loading"
      class="d-flex justify-content-center mt-5"
    >
      <div
        class="spinner-border"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            Nom
          </th>
          <th scope="col">
            Type
          </th>
          <th scope="col">
            Statut de validation
          </th>
          <th scope="col">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(document, index) in documents"
          :key="index"
          class="align-middle"
        >
          <td>
            <upload-document :file="document" />
          </td>
          <td>{{ document?.type }}</td>
          <td>
            <document-badge
              :status="{
                validated: document.validated_at,
              }"
            />
          </td>
          <td>
            <span
              v-if="!document.validated_at"
              role="button"
              class="badge bg-green mx-1"
              @click="validate(document.id)"
            >
              Valider
            </span>
            <span
              role="button"
              class="badge bg-danger mx-1"
              @click="refuse(document.id)"
            >
              Refuser
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>

import uploadDocument from '@/components/uploadDocument/UploadDocument.vue';
import DocumentBadge from '@/components/badge/DocumentBadge.vue';
import Document from '@/services/Document.js';
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
const store = useStore();


const validate = (id) => {
  Document.validate(id)
    .then(() => {
      store.dispatch("candidate/getOne", route.params.id);
    });
};
const refuse = (id) => {
  Document.refuse(id)
    .then(() => {
      store.dispatch("candidate/getOne", route.params.id);
    });
};

const props = defineProps({
  documents: {
    type: Array,
  },
  loading: Boolean,
});


</script>
