<template>
  <div>
    <div class="row mt-3 mb-5">
      <div class="col col-sm-12">
        <div class="steps d-flex">
          <div class="line-primary m-1" />
          <div class="line-primary m-1" />
          <div class="line-primary-empty m-1" />
        </div>
      </div>
    </div>
    <div v-if="route.params.profil === 'company'">
      <div class="row">
        <div class="col col-6 ">
          <label class="form-label text-purple_grey text-bold">Prénom  <required /></label>
          <input
            v-model="formData.first_name"
            :class="[{ 'invalid' : registerErrors?.first_name }, 'form-control', 'w-100']"
            placeholder="Votre prénom"
            required
          >
          <span
            v-if="registerErrors?.first_name"
            class="text-danger"
          >{{ registerErrors?.first_name[0] }}</span>
        </div>
        <div class="col col-6">
          <label class="form-label text-purple_grey text-bold">Nom <required /></label>
          <div class="input-group has-validation">
            <input
              v-model="formData.second_name"
              :class="[{ 'invalid' : registerErrors?.second_name }, 'form-control', 'w-100']"
              placeholder="Votre nom"
              required
            >
            <span
              v-if="registerErrors?.second_name"
              class="text-danger"
            >{{ registerErrors?.second_name[0] }}</span>
          </div>
        </div>
        <div class="col col-12 mt-2 ">
          <label class="form-label text-purple_grey text-bold">
            Nom de l'entreprise <required />
          </label>
          <div class="input-group has-validation">
            <input
              v-model="formData.company_name"
              :class="[{ 'invalid' : registerErrors?.company_name }, 'form-control', 'w-100']"
              placeholder="Nom de l'entreprise"
              required
            >
            <span
              v-if="registerErrors?.company_name"
              class="text-danger"
            >{{ registerErrors?.company_name[0] }}</span>
          </div>
        </div>
        <div class="col col-6  mt-2 ">
          <label class="form-label text-purple_grey text-bold">Téléphone <required /></label>
          <div class="input-group has-validation">
            <input
              v-model="formData.phone_number"
              :class="[{ 'invalid' : registerErrors?.phone_number }, 'form-control', 'w-100']"
              placeholder="Votre numéro de téléphone"
              required
            >
            <span
              v-if="registerErrors?.second_name"
              class="text-danger"
            >{{ registerErrors?.second_name[0] }}</span>
          </div>
        </div>
        <div class="col col-12 mt-2 ">
          <label class="form-label text-purple_grey text-bold">Siret <required /></label>
          <div class="input-group has-validation">
            <input
              v-model="formData.siret"
              :class="[{ 'invalid' : registerErrors?.siret }, 'form-control', 'w-100']"
              maxlength="14"
              placeholder="Siret (14 chiffres)"
              required
            >
            <span
              v-if="registerErrors?.siret"
              class="text-danger"
            >{{ registerErrors?.siret[0] }}</span>
          </div>
        </div>
        <div class="col col-12 mt-2 ">
          <label class="form-label text-purple_grey text-bold">Adresse <required /></label>
          <div class="input-group has-validation">
            <input
              v-model="formData.address1"
              :class="[{ 'invalid' : registerErrors?.address1 }, 'form-control', 'w-100']"
              placeholder="Numéro et rue"
              required
            >
            <span
              v-if="registerErrors?.address1"
              class="text-danger"
            >{{ registerErrors?.address1[0] }}</span>
          </div>
        </div>
        <div class="col col-12 mt-2 ">
          <label class="form-label text-purple_grey text-bold">Complément d'adresse</label>
          <div class="input-group has-validation">
            <input
              v-model="formData.address2"
              :class="[{ 'invalid' : registerErrors?.address2 }, 'form-control', 'w-100']"
              placeholder="Complément d'adresse"
            >
            <span
              v-if="registerErrors?.address2"
              class="text-danger"
            >{{ registerErrors?.address2[0] }}</span>
          </div>
        </div>
        <div class="col col-6 mt-2">
          <search-cities
            :errors="registerErrors?.city_id"
            :existing-city="formData.city"
            @send-city="sendCity($event)"
          />
        </div>
        <div class="col col-12 mt-2 ">
          <label class="form-label text-purple_grey text-bold">Ville</label>
          <div class="input-group has-validation">
            <input
              v-model="formData.city_name"
              :class="[{ 'invalid' : registerErrors?.city_name }, 'form-control', 'w-100']"
              placeholder="Veuillez choisir une ville"
              disabled
              required
            >
            <span
              v-if="registerErrors?.city_name"
              class="text-danger"
            >{{ registerErrors?.city_name[0] }}</span>
          </div>
        </div>

        <div class="col col-12 my-2 ">
          <label class="form-label text-purple_grey text-bold">Abonnements <required /></label>
          <div class="d-flex">
            <subscriptions-modal
              @send-to-infos="subscriptionChoice($event)"
            />
            <div
              v-if="formData.subscription_name"
              class="d-flex flex-column justify-content-center align-items-center w-50"
            >
              <span>Abonnement choisi :</span>
              <span class="text-bold card-text-lg">
                {{ formData.subscription_name }}
              </span>
            </div>
          </div>
          <span
            v-if="registerErrors?.subscription_id"
            class="text-danger"
          >{{ registerErrors?.subscription_id[0] }}</span>
        </div>
      </div>
    </div>
    <div class="row g-4">
      <div class="col col-12 ">
        <label class="form-label text-purple_grey text-bold">Email <required /></label>
        <input
          v-model="formData.email"
          type="email"
          :class="[{ 'invalid' : registerErrors?.email }, 'form-control', 'w-100']"
          placeholder="Votre adresse email"
          required
        >
        <span
          v-if="registerErrors?.email"
          class="text-danger"
        >{{ registerErrors?.email[0] }}</span>
      </div>
      <div class="col col-12 mt-2 ">
        <label class="form-label text-purple_grey text-bold">Mot de passe <required /></label>
        <div class="input-group has-validation">
          <input
            v-model="formData.password"
            :type="[showPassword ? 'text' : 'password']"
            :class="[{ 'invalid' : registerErrors?.password }, 'form-control', 'password_input']"
            placeholder="Votre mot de passe"
            required
          >
          <span
            class="input-group-text eye_icon_button d-flex align-items-center justify-content-center"
            @click="() => {showPassword = !showPassword}"
          >
            <i :class="[showPassword ? 'bi bi-eye-slash' : 'bi bi-eye']" />
          </span>
          <span
            v-if="registerErrors?.password"
            class="text-danger"
          >{{ registerErrors?.password[0] }}</span>
        </div>
      </div>
      <div class="col col-12 mt-2 ">
        <label class="form-label text-purple_grey text-bold">
          Confirmation du mot de passe <required />
        </label>
        <div class="input-group has-validation">
          <input
            v-model="formData.password_confirmation"
            :type="[showPasswordConfirmation ? 'text' : 'password']"
            :class="[{ 'invalid' : registerErrors?.password_confirmation },
                     'form-control',
                     'password_input']"
            placeholder="Confirmation du mot de passe"
            required
          >
          <span
            class="input-group-text eye_icon_button d-flex align-items-center justify-content-center"
            @click="() => {showPasswordConfirmation = !showPasswordConfirmation}"
          >
            <i :class="[showPasswordConfirmation ? 'bi bi-eye-slash' : 'bi bi-eye']" />
          </span>
          <span
            v-if="registerErrors?.password_confirmation"
            class="text-danger"
          >{{ registerErrors?.password_confirmation[0] }}</span>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col col-sm-12">
        <div class="form-check form-switch">
          <input
            v-model="formData.terms"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <label class="form-check-label mx-2">
            <span class="text-primary text-bold">
              CGU CGV <required />
            </span>
          </label>
          <span
            v-if="registerErrors?.terms"
            class="text-danger"
          > <br> {{ registerErrors?.terms[0] }}</span>
        </div>
      </div>
    </div>
    <div class="row w-100 my-3">
      <button
        class="btn-purple-gradient w-50 text-bold p-3 mt-4 rounded-3 m-auto"
        :disabled="registerLoading"
        type="submit"
        @click="onContinue"
      >
        <span
          v-if="registerLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
        S'inscrire
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import SearchCities from '@/components/searchCities/SearchCities.vue';
import SubscriptionsModal from '@/components/modal/SubscriptionsModal.vue';
import Required from '@/components/Required.vue';

const route = useRoute();
const store = useStore();

const registerErrors = computed(() => store.state.auth.register_errors);
const registerLoading = computed(() => store.state.auth.register_loading);

const formData = ref({
  email: null,
  password: null,
  password_confirmation: null,
  first_name: null,
  second_name: null,
  siret: null,
  company_name: null,
  address1: null,
  address2: '',
  zip_code: null,
  city_name: null,
  city: null,
  phone_number: null,
  terms: null,
  subscription_id: null,
  guard: null,
  subscription_name: null,
});

const sendCity = (data) => {
  if (data) {
    formData.value.city_id = data?.id;
    formData.value.zip_code = data?.zip_code;
    formData.value.city_name = data?.name;
  }
};

const emit = defineEmits(['send-infos']);

const onContinue = () => {
  let data = formData.value;
  route.params.profil === 'company' ? formData.value.guard = 'recruiter' : formData.value.guard = 'user';
  if (route.params.profil === 'candidate') {
    data = {
      email: formData.value.email,
      password: formData.value.password,
      password_confirmation: formData.value.password_confirmation,
      terms: formData.value.terms,
      guard: formData.value.guard,
    };
  }
  emit('send-infos', data);
};

const showPassword = ref(false);
const showPasswordConfirmation = ref(false);

const subscriptionChoice = (value) => {
  formData.value.subscription_id = value.id;
  formData.value.subscription_name = value.name;
};

onMounted(() => {
  store.commit('auth/setRegisterErrors', {});
});
</script>
