import { createWebHistory, createRouter } from 'vue-router';
import Auth from '@/containers/anonymous/auth/Main.vue';
import AuthenticatedMain from '@/containers/authenticated/Main.vue';
import Home from '@/containers/anonymous/Main.vue';
import CandidateProfil from '@/containers/authenticated/candidate/Profil.vue';
import CandidateVideos from '@/containers/authenticated/candidate/Videos.vue';
import MeetingRequest from '@/containers/authenticated/candidate/MeetingRequest.vue';
import CandidateDashboard from '@/containers/authenticated/candidate/Dashboard.vue';
import CandidateChat from '@/containers/authenticated/candidate/Chat.vue';
import CandidateTutorials from '@/containers/authenticated/candidate/Tutorials.vue';
import CompanyProfil from '@/containers/authenticated/company/Profil.vue';
import CompanyChat from '@/containers/authenticated/company/Chat.vue';
import CompanyTutorials from '@/containers/authenticated/company/Tutorials.vue';
import CompaniesList from '@/containers/authenticated/backoffice/company/List.vue';
import MatchingEngine from '@/containers/authenticated/backoffice/matchingengine/List.vue';
import CompanyDetail from '@/containers/authenticated/backoffice/company/Details.vue';
import CandidatesList from '@/containers/authenticated/backoffice/candidate/List.vue';
import CandidateDetail from '@/containers/authenticated/backoffice/candidate/Details.vue';
import AdminsList from '@/containers/authenticated/backoffice/admin/List.vue';
import AdminCreate from '@/containers/authenticated/backoffice/admin/Create.vue';
import CompanyOffersList from '@/containers/authenticated/company/Offers.vue';
import CompanyOfferDetail from '@/containers/authenticated/company/OfferDetails.vue';
import CompanyOfferForm from '@/containers/authenticated/company/OfferForm.vue';
import CompanyApplicationDetail from '@/containers/authenticated/company/ApplicationDetail.vue';
import CandidateOfferDetail from '@/containers/authenticated/candidate/OfferDetails.vue';
import BackofficeOffersList from '@/containers/authenticated/backoffice/offer/List.vue';
import BackofficeOfferDetail from '@/containers/authenticated/backoffice/offer/Details.vue';
import BackofficeTutorialDetail from '@/containers/authenticated/backoffice/tutorial/Details.vue';
import BackofficeTutorialsList from '@/containers/authenticated/backoffice/tutorial/List.vue';
import BackofficeTutorialCreate from '@/containers/authenticated/backoffice/tutorial/Create.vue';
import VisioPage from '@/components/jitsi/JitsiPage.vue';
import ChatList from '@/containers/authenticated/backoffice/chat/List.vue';
import ChatDetail from '@/containers/authenticated/backoffice/chat/Create.vue';
import NotFound from '@/components/NotFound.vue';
import NotAuthorize from '@/components/NotAuthorize.vue';
import EmailVerification from '@/components/EmailVerification.vue';
import CGU from '@/containers/anonymous/legal/CGU.vue';
import CGV from '@/containers/anonymous/legal/CGV.vue';
import RGPD from '@/containers/anonymous/legal/RGPD.vue';
import LegalMentions from '@/containers/anonymous/legal/LegalMentions.vue';
import Contact from '@/containers/anonymous/contact/Contact.vue';
import store from '../store/index.js';

const routes = [
  {
    path: '/home',
    component: Home,
    name: 'Home',
    meta: { private: false },
  },
  {
    path: '/register',
    component: Auth,
    name: 'Register',
    meta: { private: false },
    props: {
      action: 'register',
    },
  },
  {
    path: '/register/:profil',
    component: Auth,
    name: 'RegisterProfil',
    meta: { private: false },
    props: {
      action: 'register',
    },
  },
  {
    path: '/login/:profil',
    component: Auth,
    name: 'Login',
    meta: { private: false },
    props: {
      action: 'login',
    },
  },
  {
    path: '/forgot-password/:scope',
    component: Auth,
    name: 'ForgotPassword',
    meta: { private: false },
    props: {
      action: 'forgotPassword',
    },
  },
  {
    path: '/reset-password/:scope',
    component: Auth,
    name: 'ResetPassword',
    props: {
      action: 'resetPassword',
    },
  },
  {
    path: '/cgu',
    component: CGU,
    name: 'CGU',
    meta: { private: false },
  },
  {
    path: '/cgv',
    component: CGV,
    name: 'CGV',
    meta: { private: false },
  },
  {
    path: '/rgpd',
    component: RGPD,
    name: 'RGPD',
    meta: { private: false },
  },
  {
    path: '/legal-mentions',
    component: LegalMentions,
    name: 'LegalMentions',
    meta: { private: false },
  },
  {
    path: '/contact',
    component: Contact,
    name: 'Contact',
  },
  {
    path: '/',
    component: AuthenticatedMain,
    name: 'AuthenticatedMain',
    meta: { private: true },
    children: [
      {
        path: 'candidate/profil',
        component: CandidateProfil,
        name: 'CandidateProfil',
        meta: { scope: 'user' },
      },
      {
        path: 'candidate/videos',
        component: CandidateVideos,
        name: 'CandidateVideos',
        meta: { scope: 'user' },
      },
      {
        path: 'candidate/dashboard',
        component: CandidateDashboard,
        name: 'CandidateDashboard',
        meta: { scope: 'user' },
      },
      {
        path: 'candidate/offers/:id',
        component: CandidateOfferDetail,
        name: 'CandidateOfferDetail',
        meta: { scope: 'user' },
      },
      {
        path: 'candidate/tutorials',
        component: CandidateTutorials,
        name: 'CandidateTutorials',
        meta: { scope: 'user' },
      },
      {
        path: 'candidate/chat',
        component: CandidateChat,
        name: 'CandidateChat',
        meta: { scope: 'user' },
      },
      {
        path: 'candidate/meetings/:meetingId',
        component: MeetingRequest,
        name: 'CandidateMeetingRequest',
        meta: { scope: 'user' },
      },
      {
        path: 'company/meetings/:meetingId',
        component: MeetingRequest,
        name: 'CompanyMeetingRequest',
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/profil',
        component: CompanyProfil,
        name: 'CompanyProfil',
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/tutorials',
        component: CompanyTutorials,
        name: 'CompanyTutorials',
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/chat',
        component: CompanyChat,
        name: 'CompanyChat',
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/offers',
        component: CompanyOffersList,
        name: 'CompanyOffersList',
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/offers/create',
        component: CompanyOfferForm,
        name: 'CompanyOfferCreate',
        props: {
          bearCrumb: 'Créer une offre',
          title: 'Créer une offre',
          actionButtonText: 'Créer l\'offre',
          create: true,
          duplicateId: null,
        },
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/offers/:id',
        component: CompanyOfferDetail,
        name: 'CompanyOfferDetail',
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/offers/:id',
        component: CompanyOfferForm,
        name: 'CompanyOfferUpdate',
        props: {
          bearCrumb: 'Modifier une offre',
          title: 'Modifier l\'offre',
          actionButtonText: 'Modifier l\'offre',
          create: false,
        },
        meta: { scope: 'recruiter' },
      },
      {
        path: 'company/offers/:offer_id/applications/:matching_id',
        component: CompanyApplicationDetail,
        name: 'CompanyApplicationDetail',
        meta: { scope: 'recruiter' },
      },
      // {
      //   path: 'visio',
      //   component: VisioPage,
      //   name: 'VisioPageCompany',
      //   meta: { scope: 'recruiter' },
      // },
      {
        path: 'backoffice/companies',
        component: CompaniesList,
        name: 'CompaniesList',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/companies/:id',
        component: CompanyDetail,
        name: 'CompanyDetail',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/candidates',
        component: CandidatesList,
        name: 'CandidatesList',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/candidates/:id',
        component: CandidateDetail,
        name: 'CandidateDetail',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/admins',
        component: AdminsList,
        name: 'AdminsList',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/create',
        component: AdminCreate,
        name: 'AdminCreate',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/offers',
        component: BackofficeOffersList,
        name: 'BackofficeOffersList',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/offers/:id',
        component: BackofficeOfferDetail,
        name: 'BackofficeOfferDetail',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/tutorials',
        component: BackofficeTutorialsList,
        name: 'BackofficeTutorialsList',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/tutorials/update/:id',
        component: BackofficeTutorialDetail,
        name: 'BackofficeTutorialDetail',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/tutorials/create',
        component: BackofficeTutorialCreate,
        name: 'BackofficeTutorialCreate',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/matchingengine',
        component: MatchingEngine,
        name: 'MatchingEngine',
        meta: { scope: 'admin' },
      },
      {
        path: 'backoffice/chat',
        component: ChatList,
        name: 'ChatList',
      },
      {
        path: 'backoffice/chat/forbidden-words',
        component: ChatDetail,
        name: 'ChatDetail',
      },
      {
        path: 'visio',
        component: VisioPage,
        name: 'VisioPage',
      },
    ],
  },
  {
    path: '/email/verify/:scope',
    component: EmailVerification,
    name: 'EmailVerification',
    meta: { private: true },
  },
  { path: '/not-authorize', component: NotAuthorize, name: 'not-authorize' },
  { path: '/:catchAll(.*)', component: NotFound, name: 'not-found' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const computeScopeRedirect = (scope) => {
  const scopeRedirectList = {
    user: "CandidateDashboard",
    recruiter: "CompanyOffersList",
    admin: "CompaniesList",
  };

  return scopeRedirectList[scope];
};

router.beforeEach(async (to, from, next) => {
  if (to.meta.private === false) {
    return next();
  }

  await store.dispatch('auth/isReady');
  const isAuth = store.getters['auth/authenticated'];
  const scope = localStorage.getItem('scope');

  let computedScope = computeScopeRedirect(scope);

  const profilScope = {
    user: "candidate",
    recruiter: "company",
    admin: "admin",
  };

  const scopeRedirect = (computedScope ? { name: computedScope } : undefined);

  if (to.path === '/') {
    return next(isAuth ? scopeRedirect : { name: 'Home' });
  }

  if (to.meta.private === true && !isAuth) {
    return next({ name: 'Login', query: { redirect: to.fullPath }, params: { profil: profilScope[scope ?? to.meta?.scope ?? 'user'] } });
  }

  if (to.meta.scope && to.meta.scope !== scope && isAuth) {
    return next({ name: 'not-found', params: { catchAll: 'not-found' } });
  }

  return next();
});

export default router;
