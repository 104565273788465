export default {
  setUser(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.user = value;
  },
  setAuthenticationErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.authentication_errors = value;
  },
  setHasAttemptedToFetchUser(state) {
    state.has_attempted_to_fetch_user = true;
  },
  setEmailVerifiedAt(state) {
    if (state.user.data) {
      state.user.data.email_verified_at = new Date();
    }
  },
  setAuthenticationLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.authentication_loading = value;
  },
  setRegisterErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.register_errors = value;
  },
  setRegisterLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.register_loading = value;
  },
  setEmailVerifiedLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.email_verified_loading = value;
  },
  setResetPasswordSuccess(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.reset_password_success = value;
  },
  setResetPasswordLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.reset_password_loading = value;
  },
  setResetPasswordErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.reset_password_errors = value;
  },
  setForgotPasswordSuccess(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.forgot_password_success = value;
  },
  setForgotPasswordLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.forgot_password_loading = value;
  },
  setForgotPasswordErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.forgot_password_errors = value;
  },
  setUnreadMessages(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.unread_messages = value;
  },
};
