import Api from './Api';

export default {
  async validate(documentId) {
    return Api.post(`api/documents/${documentId}/validity`);
  },
  async refuse(documentId) {
    return Api.delete(`api/documents/${documentId}/validity`);
  },
  async get(documentId) {
    return Api.get(`api/documents/${documentId}/validity`);
  },
};