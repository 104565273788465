import MatchingEngine from "@/services/MatchingEngine";

export default {
  async getAll({ commit }, obj) {
    commit("setMatchingEnginesLoading", true);
    try {
      const matchingengines = await MatchingEngine.getAll(obj);
      commit("setMatchingEngines", matchingengines);
    } catch (error) {
      commit("setMatchingEnginesErrors", error?.response?.data?.errors);
    }
    commit("setMatchingEnginesLoading", false);
  },
};
