<template>
  <div class="p-3 p-md-5 p-sm-3 min-vh-100">
    <div class="row mt-5 text-center vh-70">
      <div class="m-auto">
        <div
          class="d-flex justify-content-center"
        >
          <div
            class="spinner-border"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const route = useRoute();

const emailVerified = computed(() => store.getters['auth/hasVerifiedEmail']);
const isAuthenticated = computed(() => store.getters['auth/authenticated']);

const scope = computed(() =>
  localStorage.getItem("scope") === "recruiter" ? "company" : "candidate",
);

const redirect = async () => {
  if (!emailVerified.value) {
    router.push({
      name: 'not-authorize',
      query: { messageType: 'emailNotVerified' },
    });
  } else {
    router.push({ name: scope.value === "company" ? "CompanyProfil" : "CandidateProfil" });
  }
};

onMounted(async () => {
  if (isAuthenticated.value) {
        await store.dispatch("auth/verifyEmail", { url: route.query.verify_url, scope: localStorage.getItem("scope") ?? 'user' });
  }
  redirect();
});

</script>
