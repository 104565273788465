<template>
  <div class="h-100">
    <div
      v-if="!hasUrl && !showFilePicker"
      class="d-flex h-100 align-items-center"
    >
      <button
        class="btn btn-outline-light"
        @click="openFileInputDialogBox"
      >
        Ajouter un fichier
      </button>
      <!-- @TODO: handle differently the deletion of a document and the deletion of a blank input -->
      <button
        type="button"
        class="
            remove-external-link
            btn-close btn-close-white
            ms-2
          "
        @click="removeFilePlaceholder"
      />
    </div>
    <div
      v-if="!hasUrl && (showFilePicker || file.file)"
      class="input-group has-validation"
    >
      <input
        :id="`fileInput_${fileIndex}`"
        :class="[{ invalid: false }, 'form-control', 'w-100']"
        type="file"
        accept="image/png, image/jpeg, .txt, .pdf "
        @change="changeFile"
      >
      <span
        v-if="false"
        class="text-danger"
      >{{
        `une erreur`
      }}</span>
    </div>
    <div
      v-if="hasUrl"
      class="d-flex h-100 align-items-center"
    >
      <a
        target="_blank"
        :href="`${VITE_API_URL}/${file && file.url}`"
      >{{
        fileNameWithExtension
      }}</a>
      <!-- @TODO: handle differently the deletion of a document and the deletion of a blank input -->
      <button
        v-if="fileIndex !== null"
        type="button"
        class="
            remove-external-link
            btn-close btn-close-white
            ms-2
          "
        data-bs-toggle="modal"
        :data-bs-target="'.delete-document-modal' + `${file?.id || ''}`"
      />
    </div>
    <modal
      :class="'delete-document-modal' + `${file?.id}`"
      title="Suppression"
      text="Etes-vous sûr de vouloir supprimer ce fichier ?"
      action-button="Valider"
      @callback="deleteFile(file?.id)"
    />
  </div>
</template>

<script setup>
import {
  ref, defineExpose, computed,
} from 'vue';
import Modal from "@/components/modal/Modal.vue";
import { useStore } from 'vuex';
const { VITE_API_URL } = import.meta.env;
const store = useStore();

// Component properties
const props = defineProps({
  file: Object,
  fileIndex: { type: Number, default: null },
});

const hasUrl = computed(() => (props.file && props.file.url));
const showFilePicker = ref(null);

const fileNameWithExtension = computed(
  () => `${props.file && props.file.name}.`
  + (props?.file?.url?.split("documents/").pop().split(".").pop()),
);

// Component emitters
const emit = defineEmits([
  'changeFile', 'removeFile',
]);

const openFileInputDialogBox = () => {
  showFilePicker.value = true;
  setTimeout(() => {
    document.getElementById(`fileInput_${props.fileIndex}`).click();
  }, 70);
};

const removeFilePlaceholder = () => {
  emit('removeFile', {
    fileIndex: props.fileIndex,
  });
};

const deleteFile = (id) => {
  store.dispatch('candidate/removeDocument',{ id });
  removeFilePlaceholder();
};

const changeFile = (event) => {
  emit('changeFile', {
    fileIndex: props.fileIndex,
    file: event.target.files[0],
  });
};
</script>