<template>
  <div class="w-100 pb-5">
    <div class="row g-4">
      <div class="col col-12 col-md-6 col-sm-12 col-lg-4">
        <search-jobs
          :errors="profilErrors?.job_id"
          :existing-job="formData.job"
          multiple
          @send-job="sendJob($event)"
        />
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Type de contrat
          <required />
        </label>
        <div class="input-group has-validation">
          <v-select
            v-model="formData.contract_types"
            :class="[{ invalid: profilErrors?.contract_type }, 'w-100']"
            label="name"
            multiple
            value="option"
            :options="contractTypes"
          />
          <span
            v-if="profilErrors?.contract_types"
            class="text-danger"
          >{{
            profilErrors?.contract_types[0]
          }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-4">
        <label class="form-label text-purple_grey text-bold">
          Diplôme obtenu dans ce domaine
          <required />
        </label>
        <div class="input-group has-validation">
          <select
            v-model="formData.degree_level_id"
            :class="[
              { invalid: profilErrors?.degree_level_id },
              'form-select',
              'w-100',
            ]"
            required
          >
            <option
              v-for="level in degreeLevel"
              :key="level.id"
              :value="level.id"
            >
              {{ level.name }}
            </option>
          </select>
          <span
            v-if="profilErrors?.degree_level_id"
            class="text-danger"
          >{{
            profilErrors?.degree_level_id[0]
          }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Nombre d'années d’expérience dans ce domaine
        </label>
        <div class="input-group has-validation">
          <input
            v-model="formData.years_of_experience"
            :class="[
              { invalid: profilErrors?.years_of_experience },
              'form-control',
              'w-100',
            ]"
            placeholder="Nombre d'années"
            required
          >
          <span
            v-if="profilErrors?.years_of_experience"
            class="text-danger"
          >{{
            profilErrors?.years_of_experience[0]
          }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Salaire annuel minimum désiré : {{ formData.salary_minimum }}€
        </label>
        <input
          v-model="formData.salary_minimum"
          type="range"
          class="form-range"
          min="15000"
          :max="formData.salary_maximum"
          step="1000"
        >
        <span
          v-if="profilErrors?.salary_minimum"
          class="text-danger"
        >{{
          profilErrors?.salary_minimum[0]
        }}</span>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">
          Salaire annuel maximum désiré : {{ formData.salary_maximum }}€
        </label>
        <input
          v-model="formData.salary_maximum"
          type="range"
          class="form-range"
          :min="formData.salary_minimum"
          max="50000"
          step="1000"
        >
        <span
          v-if="profilErrors?.salary_maximum"
          class="text-danger"
        >{{
          profilErrors?.salary_maximum[0]
        }}</span>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3 mt-5">
        <div class="form-check form-switch mt-2">
          <label class="form-check-label ms-2">
            <span class="text-purple_grey text-bold">
              Disponible maintenant
            </span>
          </label>
          <input
            v-model="formData.available_now"
            class="form-check-input custom-switch"
            type="checkbox"
          >
          <span
            v-if="profilErrors?.available_now"
            class="text-danger"
          >
            <br>
            {{ profilErrors?.available_now[0] }}</span>
        </div>
      </div>
      <div
        v-if="!formData.available_now"
        class="col col-12 col-md-6 col-sm-12 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">Disponibilité
          <required />
        </label>
        <div class="input-group has-validation">
          <input
            v-model="formData.availability"
            :class="[
              { invalid: profilErrors?.availability },
              'form-control',
              'w-100',
            ]"
            placeholder="Disponibilité"
            required
            type="date"
          >
          <span
            v-if="profilErrors?.availability"
            class="text-danger"
          >{{
            profilErrors?.availability[0]
          }}</span>
        </div>
      </div>
      <div class="col col-12 col-md-6 col-sm-12 col-lg-3">
        <label class="form-label text-purple_grey text-bold">Télétravail
          <required />
        </label>
        <select
          v-model="formData.telework"
          :class="[{ invalid: profilErrors?.telework }, 'form-select', 'w-100']"
        >
          <option
            v-for="option in teleworkOptions"
            :key="option.key"
            :value="option.key"
          >
            {{ option.name }}
          </option>
        </select>
        <span
          v-if="profilErrors?.telework"
          class="text-danger"
        >
          <br>
          {{ profilErrors?.telework[0] }}</span>
      </div>
      <div
        v-if="formData.telework !== 'full'"
        class="col col-12 col-md-6 col-sm-12 col-lg-3"
      >
        <search-cities
          :errors="profilErrors?.city_id"
          :existing-city="formData.city"
          @send-city="sendCity($event)"
        />
      </div>
      <div
        v-if="formData.telework !== 'full'"
        class="col col-12 col-md-6 col-sm-12 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">Ville</label>
        <div class="input-group has-validation">
          <input
            v-model="formData.city_name"
            disabled
            :class="[
              { invalid: profilErrors?.city_name },
              'form-control',
              'w-100',
            ]"
            placeholder="Veuillez choisir une ville"
          >
          <span
            v-if="profilErrors?.city_name"
            class="text-danger"
          >{{
            profilErrors?.city_name[0]
          }}</span>
        </div>
      </div>
      <div
        v-if="formData.telework !== 'full'"
        class="col col-12 col-md-6 col-sm-12 col-lg-3"
      >
        <label class="form-label text-purple_grey text-bold">
          Rayon autour de la ville : {{ formData.radius }} km
        </label>
        <input
          v-model="formData.radius"
          type="range"
          class="form-range"
          min="0"
          max="200"
          step="10"
        >
        <span
          v-if="profilErrors?.radius"
          class="text-danger"
        >{{
          profilErrors?.radius[0]
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import SearchCities from "@/components/searchCities/SearchCities.vue";
import SearchJobs from "@/components/searchJobs/SearchJobs.vue";
import Required from "@/components/Required.vue";
import normalizer from "@/utils/normalizer";

const store = useStore();
const contractTypes = computed(
  () => store.state?.contractType?.contract_types?.data?.data,
);
const degreeLevel = computed(
  () => store.state?.degreeLevel?.degree_levels?.data?.data,
);
const profilErrors = computed(
  () => store.state?.candidate?.candidate_detail_errors?.research,
);

const props = defineProps({
  existingCandidate: Object,
});
const profileData = computed(() => props.existingCandidate);
const formData = ref({
  city: "",
  city_id: null,
  city_name: null,
  zip_code: null,
  salary_minimum: 25000,
  salary_maximum: 200000,
  years_of_experience: null,
  availability: null,
  degree_level_id: null,
  job: [],
  job_id: [],
  contract_types: [],
  available_now: false,
  radius: 10,
  telework: "none",
});

const teleworkOptions = ref([
  { key: "none", name: "Pas de télétravail" },
  { key: "partial", name: "Partiel" },
  { key: "full", name: "Télétravail uniquement" },
]);

const emit = defineEmits(["sendData", "errorExist"]);

const onSend = () => {
  const tempFormData = ref({ ...formData.value });
  if (formData.value.available_now) {
    delete tempFormData.value.availability;
  }
  if (formData.value.telework === "full") {
    delete tempFormData.value.city_id;
    delete tempFormData.value.radius;
    delete tempFormData.value.zip_code;
  }

  tempFormData.value.contract_types = [];
  for (const elt of formData.value.contract_types) {
    tempFormData.value.contract_types = [
      ...tempFormData.value.contract_types,
      elt.id,
    ];
  }

  tempFormData.value.job = [];
  for (const jo of formData.value.job) {
    tempFormData.value.job = [
      ...tempFormData.value.job,
      jo.id,
    ];
  }

  emit("sendData", tempFormData.value);
};
watch(
  () => ({
    ...formData.value,
  }),
  (value) => {
    if (value) {
      onSend();
    }
  },
);

const sendCity = (data) => {
  if (data) {
    formData.value.city_id = data?.id;
    formData.value.zip_code = data?.zip_code;
    formData.value.city_name = data?.name;
  }
};

const sendJob = (data) => {
  const job_ids = [];
  if(data.length > 0 && data.length < 4){
    for (let index = 0; index < data.length; index++) {
      job_ids.push(data[index].id);
    }
  }
  formData.value.job_id = job_ids;

};

watch(profilErrors, (value) => {
  if (value && Object.keys(value).length !== 0) {
    emit("errorExist");
  }
});

watch(profileData, (value) => {
  if (value) {
    formData.value = { ...profileData.value };
    if (profileData?.value?.job?.id) {
      formData.value.job_id = profileData.value.job.id;
    }
    formData.value.availability = normalizer
      .formatDate(profileData.value.availability)
      .split("/")
      .reverse()
      .join("-");
    formData.value.degree_level_id = profileData.value.degreeLevel.id;
  }
});
onMounted(() => {
  store.dispatch("job/getAll");
  store.dispatch("contractType/getAll");
  store.dispatch("degreeLevel/getAll");
  onSend();
});
</script>
