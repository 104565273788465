<template>
  <div>
    <div
      class="company-profil-ban-full ban-full mb-5 bg-dark_grey"
    />
    <h1
      class="d-flex title-page text-black
     flex-column align-items-end justify-content-end position-relative"
    >
      <b class="text-uppercase">Mon entreprise</b>
    </h1>

    <div class="page-content">
      <div
        v-if="notifVerification?.type === 'cp_refused'"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        <h4>
          Les dernières modifications de votre profil ont été refusé
        </h4>
        Motif : {{ notifVerification?.ground_of_refusal }}
      </div>
      <div
        v-if="notifVerification?.type === 'cp_accepted'"
        class="alert alert-green alert-dismissible fade show"
        role="alert"
      >
        <h4>
          Les dernières modifications de votre profil ont été validées
        </h4>
      </div>
      <ul
        id="myTab"
        class="nav nav-pills"
        role="tablist"
      >
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            id="profil-tab"
            class="nav-link active"
            data-bs-toggle="tab"
            data-bs-target="#profil"
            type="button"
            role="tab"
            aria-controls="profil"
            aria-selected="true"
          >
            <span>
              Mon profil
              <div class="line-primary" />
            </span>
          </button>
        </li>
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            id="account-tab"
            class="nav-link"
            data-bs-toggle="tab"
            data-bs-target="#account"
            type="button"
            role="tab"
            aria-controls="account"
            aria-selected="false"
          >
            <span>
              Mon compte
              <div class="line-primary" />
            </span>
          </button>
        </li>
      </ul>
      <div
        id="myTabContent"
        class="tab-content"
      >
        <div
          id="profil"
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="profil-tab"
        >
          <profil @send-notif-verification="getNotifVerification($event)" />
        </div>
        <div
          id="account"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="account-tab"
        >
          <account />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import Profil from '@/components/company/Profil.vue';
import Account from '@/components/company/Account.vue';
import normalizer from '@/utils/normalizer';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

const router = useRouter();

const notifVerification = ref();

const getNotifVerification = (value) => {
  notifVerification.value = value;
};

</script>
