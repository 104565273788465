<template>
  <form
    class="needs-validation"
    novalidate
    @submit.prevent="resetPassword"
  >
    <div class="container p-md-5 p-sm-3 min-vh-100 d-flex flex-column">
      <div class="row">
        <div class="col col-sm-12">
          <div class="mb-4">
            <router-link :to="{ name: 'Home' }">
              <img
                src="@/assets/images/icons/arrow-left.svg"
                alt="Retour à l'accueil"
                class="me-2"
              >
              <span>Retour à l'accueil</span>
            </router-link>
          </div>
          <h1 class="lh-1">
            <span class="text-medium">Changer</span>
            <br>
            <span class="text-bold">mon mot de passe</span>
          </h1>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col col-sm-12">
          <div class="line-primary" />
        </div>
      </div>
      <div
        v-if="!resetPasswordSuccess"
        class="row d-flex flex-column mt-5"
      >
        <div class="row my-5">
          <div class="col col-sm-12">
            <p>
              Pour réinitialiser votre mot de passe, veuillez renseigner votre
              adresse email.
            </p>
          </div>
        </div>
        <div class="col col-12">
          <label class="form-label text-purple_grey text-bold">Email</label>
          <input
            v-model="formData.email"
            type="email"
            :class="[
              { invalid: resetPasswordErrors?.email },
              'form-control',
              'w-100',
            ]"
            placeholder="Votre adresse email"
            required
          >
          <span
            v-if="resetPasswordErrors?.email"
            class="text-danger"
          >{{
            resetPasswordErrors?.email[0]
          }}</span>
        </div>
        <div class="col col-12 mt-2">
          <label class="form-label text-purple_grey text-bold">Nouveau mot de passe</label>
          <div class="input-group has-validation">
            <input
              v-model="formData.password"
              type="password"
              :class="[
                { invalid: resetPasswordErrors?.password },
                'form-control',
                'w-100',
              ]"
              placeholder="Votre mot de passe"
              required
            >
            <span
              v-if="resetPasswordErrors?.password"
              class="text-danger"
            >{{
              resetPasswordErrors?.password[0]
            }}</span>
          </div>
        </div>
        <div class="col col-12 mt-2">
          <label class="form-label text-purple_grey text-bold">Confirmation du nouveau mot de passe</label>
          <div class="input-group has-validation">
            <input
              v-model="formData.password_confirmation"
              type="password"
              :class="[
                { invalid: resetPasswordErrors?.password_confirmation },
                'form-control',
                'w-100',
              ]"
              placeholder="Confirmation du mot de passe"
              required
            >
            <span
              v-if="resetPasswordErrors?.password_confirmation"
              class="text-danger"
            >{{ resetPasswordErrors?.password_confirmation[0] }}</span>
          </div>
        </div>
        <div class="col col-12 mt-2">
          <div class="row">
            <div class="col col-sm-12 mt-4">
              <button
                class="btn btn-purple-gradient w-100 text-bold p-3"
                type="submit"
                :disabled="resetPasswordLoading"
              >
                <span
                  v-if="resetPasswordLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Changer mon mot de passe
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="row d-flex flex-column justify-content-center mt-5 text-center"
      >
        <img
          src="@/assets/images/icons/verification.webp"
          alt="Mot de passe mis à jour"
          class="w-50 p-5 mt-3 m-auto"
        >
        <h1 class="text-bold">
          Mot de passe mis à jour !
        </h1>
        <router-link
          :to="{
            name: 'Login',
            params: {
              profil:
                route.params.scope === 'user'
                  ? 'candidate'
                  : route.params.scope === 'admin'
                    ? 'admin'
                    : 'company',
            },
          }"
        >
          <button
            class="btn btn-purple-gradient px-4 py-2 mt-4 text-bold"
            type="button"
          >
            Je me connecte
          </button>
        </router-link>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useStore();
const resetPasswordSuccess = computed(
  () => store.state.auth.reset_password_success,
);
// const resetPasswordSuccess = true;
const resetPasswordLoading = computed(
  () => store.state.auth.reset_password_loading,
);
const resetPasswordErrors = computed(
  () => store.state.auth.reset_password_errors,
);

const formData = ref({
  email: route.query.email,
  password: "",
  password_confirmation: "",
  token: route.query.token,
  verify: route.query?.verify,
  guard: route.params.scope,
});
const resetPassword = () => {
  store.dispatch("auth/resetPassword", formData.value);
};

onMounted(() => {
  store.commit("auth/setResetPasswordErrors", {});
});
</script>
