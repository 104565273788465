<template>
  <div class="w-100 my-5 mb-lg-3">
    <h4 class="text-primary text-bold">
      Mon compte
    </h4>
    <hr class="w-100">
    <h5 class="my-3">
      Ajouter un recruteur
    </h5>
    <p>
      Créez jusqu’à 10 profils recruteur.
    </p>
    <div class="row g-4">
      <div class="col col-12 col-sm-6 input-sizer">
        <label class="form-label text-purple_grey text-bold">Prénom du nouvel utilisateur <required /></label>
        <input
          v-model="formData.first_name"
          type="email"
          :class="[
            { 'invalid' : createRecruiterErrors?.first_name }, 'form-control', 'w-100']"
          placeholder="Prénom du nouvel utilisateur"
          required
        >
        <span
          v-if="createRecruiterErrors?.first_name"
          class="text-danger"
        >{{ createRecruiterErrors?.first_name[0] }}</span>
      </div>
      <div class="col col-12 col-sm-6 input-sizer">
        <label class="form-label text-purple_grey text-bold">Nom du nouvel utilisateur <required /></label>
        <input
          v-model="formData.second_name"
          type="email"
          :class="[{ 'invalid' : createRecruiterErrors?.second_name }, 'form-control', 'w-100']"
          placeholder="Nom du nouvel utilisateur"
          required
        >
        <span
          v-if="createRecruiterErrors?.second_name"
          class="text-danger"
        >{{ createRecruiterErrors?.second_name[0] }}</span>
      </div>
      <div class="col col-12 input-sizer">
        <label class="form-label text-purple_grey text-bold">Email du nouvel utilisateur <required /></label>
        <input
          v-model="formData.email"
          type="email"
          :class="[{ 'invalid' : createRecruiterErrors?.email }, 'form-control', 'w-100']"
          placeholder="Adresse email du nouvel utilisateur"
          required
        >
        <span
          v-if="createRecruiterErrors?.email"
          class="text-danger"
        >{{ createRecruiterErrors?.email[0] }}</span>
      </div>
    </div>
    <button
      class="btn btn-purple-gradient text-bold my-3"
      :disabled="createRecruiterLoading"
      @click="addRecruiter"
    >
      <div
        v-if="createRecruiterLoading"

        class="spinner-border spinner-border-sm"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      Envoyer l'email d'inscription
    </button>
    <span
      v-if="addRecruiterSuccess"
      class=" col-auto text-green ms-4"
    >L'email de création de compte a bien été envoyé !</span>
    <span
      v-if="createRecruiterMessageError && !createRecruiterErrors"
      class=" col-auto text-danger ms-4"
    >{{ createRecruiterMessageError?.message }}</span>
    <h5 class="my-3">
      Liste des recruteurs
    </h5>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            Nom
          </th>
          <th scope="col">
            email
          </th>
          <th scope="col">
            Date de création
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="recruiter in recruiters"
          :key="recruiter.id"
          class="align-middle"
        >
          <td>{{ recruiter.first_name }} {{ recruiter.second_name }}</td>
          <td>{{ recruiter.email }}</td>
          <td>{{ normalizer.formatDate(recruiter.created_at) }}</td>
        </tr>
      </tbody>
    </table>
    <hr class="w-100 mt-4">
    <h5 class="my-3">
      Changer mon plan d'abonnement
    </h5>
    <p>
      Vos besoins évoluent ? Vous pouvez ajuster votre abonnement sur simple demande.
    </p>
    <router-link :to="{ name : 'Contact', query :{ subject : 'changeSubscription' } }">
      <button class="btn btn-purple-gradient text-bold my-3">
        Faire une demande de changement d'abonnement
      </button>
    </router-link>
    <hr class="w-100">
    <h5 class="my-3">
      Changement de mot de passe
    </h5>
    <p>
      Pour changer de mot de passe, veuillez cliquer sur le bouton ci-dessous. <br>
      Vous receverez par la suite un mail contenant les informations à suivre.
    </p>
    <button
      class="btn btn-purple-gradient text-bold mt-3"
      data-bs-toggle="modal"
      data-bs-target=".modal"
    >
      Changer mon mot de passe
    </button>
    <p>
      <span
        v-if="resetPasswordSuccess"
        class=" col-auto text-green"
      >
        L'email de réinitialisation de mot de passe a bien été envoyé !
      </span>
    </p>
    <modal
      class="modal"
      :title="'Réinitialisation de mot de passe'"
      :text="'Etes-vous sûr de vouloir réinitialiser votre mot de passe ?'"
      :action-button="'Réinitialiser mon mot de passe'"
      @callback="resetPassword"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import Modal from '@/components/modal/Modal.vue';
import normalizer from '@/utils/normalizer';
import Required from '@/components/Required.vue';

const store = useStore();
const createRecruiterErrors = computed(() => store.state.company.add_recruiter_errors);
const createRecruiterMessageError = computed(() => store.state.company.add_recruiter_message_error);
const createRecruiterLoading = computed(() => store.state.company.add_recruiter_loading);
const subscriptionsErrors = computed(() => store.state?.subscriptions?.subscriptions_errors);
const subscriptions = computed(() => store.state?.subscriptions?.subscriptions?.data);
const recruiters = computed(() => store.state?.company?.recruiters?.data?.data);

const formData = ref({
  subscription_id: 1,
  email: null,
  first_name: null,
  second_name: null,
});
const addRecruiterSuccess = ref(false);
const resetPasswordSuccess = ref(false);
const addRecruiter = () => store.dispatch(
  'company/addRecruiter',
  {
    data: formData.value,
    onSuccess: () => {
      addRecruiterSuccess.value = true;
    },
    onFail: () => {
      addRecruiterSuccess.value = false;
    },
  },
);

const user = JSON.parse(localStorage.getItem('user'))?.data;

const resetPassword = () => store.dispatch(
  'auth/forgotPassword',
  {
    email: user.email,
    guard: 'recruiter',
    onSuccess: () => {
      resetPasswordSuccess.value = true;
    },
  },
);

onMounted(() => {
  store.dispatch('subscriptions/getAll');
  store.dispatch('company/getAllRecruiters');
  resetPasswordSuccess.value = false;
  addRecruiterSuccess.value = false;
});
</script>
