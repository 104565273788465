import Api from './Api';

export default {
  async getAll() {
    return Api.get('api/videos');
  },
  async create(data) {
    return Api.post('api/videos/create', data);
  },
  async update(data) {
    return Api.post(`api/videos/${data.id}/update`, data.data);
  },
  async verifyVideo(id) {
    return Api.post(`api/user-videos/${id}/accept`);
  },
  async refuseVideo(data) {
    return Api.post(`api/user-videos/${data.id}/refuse`, { reasons: data.description });
  },
};
