<template>
  <div>
    <h2>Les termes interdits</h2>
    <nav
      class="mb-5"
      style="--bs-breadcrumb-divider: '•';"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{}">
            Accueil
          </router-link>
        </li>
        <li
          class="breadcrumb-item"
        >
          <router-link :to="{ name: 'ChatList' }">
            Liste des messages innappropriés
          </router-link>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Les terms interdits
        </li>
      </ol>
    </nav>
    <div class="my-4">
      <router-link :to="{ name: 'ChatList' }">
        <img
          src="@/assets/images/icons/arrow-left.svg"
          alt="Retour aux administrateurs"
          class="me-2"
        >
        <span>Retour aux messages innappropriés</span>
      </router-link>
    </div>
    <h1 class="mt-5">
      Ajouter un mot interdit
    </h1>
    <hr>
    <div>
      <label class="form-label text-purple_grey text-bold">Mot interdit</label>
      <input
        v-model="formData.forbidden_words"
        type="email"
        :class="[{ 'invalid' : addWordError?.forbidden_words },'form-control', 'input-sizer']"
        placeholder="Mot interdit"
        required
      >
      <span
        v-if="addWordError?.forbidden_words"
        class="text-danger"
      >{{ addWordError?.forbidden_words[0] }}</span>
    </div>

    <button
      class="btn btn-purple-gradient text-bold mt-3"
      :disabled="addWordLoading"
      @click="addWord"
    >
      <span
        v-if="addWordLoading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
      Ajouter à la liste
    </button>
    <!-- @todo: create_admin not defined, should be displayed when email has been sent -->
    <!-- <div
      v-if="create_admin"
      class="text-green"
    >
      Email Envoyé !
    </div> -->
    <h5 class="mt-5">
      Liste des mots interdits
    </h5>
    <hr>
    <ul>
      <li
        v-for="word in forbiddenWords"
        :key="word.id"
      >
        {{ word?.forbidden_words }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import {
  computed, ref, onMounted, watch,
} from 'vue';
import { useStore } from 'vuex';

const addWordError = computed(() => store.state.chat.saveSettingErrors);
const addWordLoading = computed(() => store.state.chat.saveSettingLoading);
const wordAdded = computed(() => store.state.chat.setting.data);

const store = useStore();
const formData = ref({
  forbidden_words: null,
});
const forbiddenWords = ref([]);

watch((wordAdded), (value) => {
  if (value.data?.length) {
    forbiddenWords.value = value.data;
  }
});

const addWord = () => {
  store.dispatch('chat/saveSetting', formData.value);
};

onMounted(() => {
  store.dispatch('chat/setting');
});

</script>
