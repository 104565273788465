<template>
  <div>
    <h2>Matching offres / candidats</h2>
    <nav
      style="--bs-breadcrumb-divider: '•'"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Accueil</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          Matching offres / candidats
        </li>
      </ol>
    </nav>
    <counter-badge
      :value="matchesCount"
      unit="match(s)"
      text="sur la plateforme"
      badge-state="success"
    />
    <br>
    <div class="form-group row mb-2">
      <label class="form-label text-purple_grey text-bold col-1">Rechercher :
      </label>
      <input
        type="text"
        class="col-3"
        @input="dosearch"
      >
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            Nom et prénom
          </th>
          <th scope="col">
            Offre
          </th>
          <th scope="col">
            Compagnie
          </th>
          <th scope="col">
            Score
          </th>
          <th scope="col">
            Statut coté l'utilisateur
          </th>
          <th scope="col">
            Statut coté recruteur
          </th>
          <th scope="col">
            Dernière modification
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="matchingengine in matchingengines"
          :key="matchingengine.id"
          class="align-middle"
        >
          <td>
            <router-link
              :to="`/backoffice/candidates/${matchingengine?.user_id}`"
            >
              {{ matchingengine?.first_name }} {{ matchingengine?.last_name }}
            </router-link>
          </td>
          <td>
            <router-link
              :to="{
                name: 'BackofficeOfferDetail',
                params: { id: matchingengine?.offer_id },
              }"
            >
              {{ matchingengine?.offer }}
            </router-link>
          </td>
          <td>
            <router-link
              :to="{
                name: 'CompanyDetail',
                params: { id: matchingengine?.company_id },
              }"
            >
              {{ matchingengine?.company_name }}
            </router-link>
          </td>
          <td>{{ matchingengine?.score }}</td>
          <td>{{ matchingengine?.user_status }}</td>
          <td>{{ matchingengine?.recruiter_status }}</td>
          <td :title="matchingengine?.updated_at">
            {{ normalizer.formatDate(matchingengine?.updated_at) }}
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :meta="matchingenginesPaginate"
      @change-page="changePage($event)"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import normalizer from "@/utils/normalizer";
import Pagination from "@/components/pagination/Pagination.vue";
import CounterBadge from '@/components/badge/CounterBadge.vue';

const store = useStore();
const matchingengines = computed(
  () => store.state?.matchingengine?.matchingengines?.data?.data,
);
const matchingenginesPaginate = computed(
  () => store.state?.matchingengine?.matchingengines?.data?.meta,
);

const matchesCount = ref(0);

const changePage = (p) => {
  store.dispatch("matchingengine/getAll", { page: p });
};

const dosearch = (search) => {
  store.dispatch("matchingengine/getAll", {
    page: 1,
    search: search.target.value,
  });
};

onMounted(() => {
  store.dispatch("matchingengine/getAll")
    .then(() => {
      matchesCount.value = store.state?.matchingengine?.matchingengines?.data?.meta?.total;
      // @TODO: get the number of matching since last connexion
    });
});
</script>
