<template>
  <form
    class="needs-validation"
    novalidate
    @submit.prevent="login"
  >
    <div
      class="
        container
        p-md-5 p-sm-3
        min-vh-100
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div>
        <div class="row">
          <div class="col col-sm-12">
            <div class="mb-4">
              <router-link :to="{ name: 'Home' }">
                <img
                  src="@/assets/images/icons/arrow-left.svg"
                  alt="Retour à l'accueil"
                  class="me-2"
                >
                <span>Retour à l'accueil</span>
              </router-link>
            </div>
            <h1 class="lh-1">
              <span class="text-medium">Se</span>
              <br>
              <span class="text-bold">connecter</span>
            </h1>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col col-sm-12">
            <div class="line-primary" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-12">
          <p
            v-if="profil.title"
            class="fw-bold mb-0"
          >
            {{ profil.title }}
          </p>
          <p>{{ profil.text }}</p>
        </div>
      </div>

      <div v-if="route.query.verify_url">
        <p class="text-bold">
          Une fois connecté, la validation de votre email sera effectuée.
        </p>
      </div>

      <div class="row">
        <div class="col col-12">
          <label class="form-label text-purple_grey text-bold">Identifiant <required /></label>
          <input
            v-model="formData.email"
            type="email"
            :class="[{ invalid: authErrors?.email }, 'form-control', 'w-100']"
            placeholder="Votre adresse email"
            required
          >
          <span
            v-if="authErrors?.email"
            class="text-danger"
          >{{
            authErrors?.email[0]
          }}</span>
        </div>
        <div class="col col-12 mt-2">
          <label class="form-label text-purple_grey text-bold">Mot de passe <required /></label>
          <div class="input-group flex-nowrap has-validation">
            <input
              v-model="formData.password"
              :type="[showPassword ? 'text' : 'password']"
              :class="[
                { invalid: authErrors?.password },
                'form-control',
                'password_input'
              ]"
              placeholder="Votre mot de passe"
              required
            >
            <span
              class="input-group-text eye_icon_button d-flex align-items-center justify-content-center"
              @click="() => {showPassword = !showPassword}"
            >
              <i :class="[showPassword ? 'bi bi-eye-slash' : 'bi bi-eye']" />
            </span>
          </div>
          <span
            v-if="authErrors?.password"
            class="text-danger"
          >{{
            authErrors?.password[0]
          }}</span>
          <p class="text-end mt-2 text-light_grey text-bold">
            <router-link
              :to="{
                name: 'ForgotPassword',
                params: { scope: profil.guard },
              }"
            >
              Mot de passe oublié?
            </router-link>
          </p>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col col-sm-12">
            <div class="form-check form-switch">
              <input
                v-model="formData.remember"
                class="form-check-input custom-switch"
                type="checkbox"
              >
              <label class="form-check-label mx-2">
                <span class="text-primary text-bold"> Rester connecté </span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-sm-12 mt-4">
            <button
              class="btn btn-purple-gradient w-100 text-bold p-3"
              type="submit"
              :disabled="authenticationLoading"
            >
              <span
                v-if="authenticationLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Se connecter
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-12 text-center">
          <router-link :to="{ name: 'Register' }">
            <span class="text-center">Je n'ai pas encore de compte</span>
          </router-link>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Required from "@/components/Required.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const authErrors = computed(() => store.state.auth.authentication_errors);
const authenticationLoading = computed(
  () => store.state.auth.authentication_loading,
);

const props = defineProps({
  profil: Object,
});

const showPassword = ref(false);

const formData = ref({
  email: "",
  password: "",
  guard: props.profil.guard,
  remember: false,
});

const login = async () => {
  await store.dispatch("auth/login", {
    data: formData.value,
    onSuccess: async () => {
      const redirect = route.query.redirect;
      const redirectIsNotSameAsCurrentRoute = redirect !== route.path && redirect !== route.name && redirect !== route.fullPath;

      if (redirect && redirectIsNotSameAsCurrentRoute) {
        return router.push(redirect);
      }

      if (props.profil.guard === "user") {
        return router.push({ name: "CandidateDashboard" });
      }
      if (props.profil.guard === "recruiter") {
        return router.push({ name: "CompanyOffersList" });
      }

      return router.push({ name: "CompaniesList" });
    },
  });
};

onMounted(() => {
  store.commit("auth/setAuthenticationErrors", {});
});
</script>