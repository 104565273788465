<template>
  <div>
    <chat-window
      :current-user-id="`${scope}-${userDetails.id}`"
      :rooms="rooms"
      :room-id="roomId"
      :rooms-order="null"
      :rooms-loaded="chatsLoading"
      :messages-loaded="messageLoaded"
      :messages="messages"
      :height="'calc(100vh - 280px)'"
      :theme="'dark'"
      :styles="styles"
      :show-audio="false"
      :show-add-room="false"
      :text-messages="textMessages"
      :emojis-suggestion-enabled="false"
      :show-files="false"
      :show-emojis="false"
      :show-reaction-emojis="false"
      :message-selection-action="false"
      :message-actions="[]"
      :menu-actions=" scope === 'recruiter' ?
        [
          { name: 'convDeactivation', title: deactivateTextBtn },
          { name: 'showCalendarModal', title: 'Fixer un RDV' },
        ] : []"
      :show-footer="rooms.filter((elt)=> elt.roomId === roomId)[0]?.disabled === 0"
      :load-first-room="false"
      @menu-action-handler="menuActionHandler"
      @fetch-messages="onFetchMessages"
      @send-message="sendMessage"
    >
      <template #room-list-options="room">
        <div
          v-if="room.room.disabled === 1"
          class="text-danger card-text-md deactivate-room-btn rounded p-1"
        >
          La conversation est désactivée
        </div>
      </template>
    </chat-window>
    <div
      ref="banModalElt"
      data-bs-keyboard="false"
      class="modal fade"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content  bg-dark_grey">
          <div class="modal-header">
            <h5 class="modal-title">
              Terme interdit
            </h5>
            <button
              type="button"
              class="btn-close bg-light"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body my-3">
            <span class="text-bold">Attention !</span> <br>
            Le message que vous voulez envoyer contient un terme interdit,
            le message n'a donc pas été transmit.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-purple-gradient text-bold"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="disableModalElt"
      data-bs-keyboard="false"
      class="modal fade"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content  bg-dark_grey">
          <div class="modal-header">
            <h5 class="modal-title">
              Conversation désactivée
            </h5>
            <button
              type="button"
              class="btn-close bg-light"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body my-3">
            La conversation a été désactivée par le recruteur,
            il n'est plus possible d'envoyer de message.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-purple-gradient text-bold"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <calendar-modal
      ref="calendarModal"
      :recruiter="recruiter?.data"
      :candidate="candidate"
    />
  </div>
</template>

<script setup>

import {
  ref, onMounted, computed, watch, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useRoute } from 'vue-router';
import visioIconSrc from '@/assets/images/icons/camera-video.svg';
import { Modal } from 'bootstrap';
import CalendarModal from "@/components/calendar/CalendarModal.vue";

const store = useStore();
const route = useRoute();
const profile = JSON.parse(localStorage.getItem('user'));
const recruiter = computed(() => store.getters['auth/user']);
const candidate =  ref(null);
const scope = localStorage.getItem('scope');
const chats = computed(() => store.state.chat?.conversations?.data);
const chatsLoading = computed(() => store.state.chat?.conversationsLoading);
const messageErrors = computed(() => store.state.chat?.messageErrors);
const allMessages = computed(() => store.state.chat?.messages.data);
// const messageLoading = computed(() => store.state.chat?.messagesLoading);
const rooms = ref([]);
const roomId = ref(null);
const deactivateTextBtn = computed(() => (rooms?.value?.filter(
  (elt) => elt.roomId === roomId?.value,
)?.[0]?.disabled === 0
  ? 'Désactiver la conversation'
  : 'Activer la conversation'));
const messages = ref([]);
const messageLoaded = ref(false);
const newChat = ref({
  content: '',
  conversation_id: '',
  recruiter_id: '',
  user_id: '',
  matched_id: '',
  last_message_id: '',
});
const {
  VITE_API_URL, VITE_PLATFORM_URL, VITE_MERCURE_TOKEN, VITE_MERCURE_URL,
} = import.meta.env;
const readNewMessages = ref(0);

const calendarModal = ref(null);

const userDetails = ref({
  id: profile?.data?.id,
  email: profile?.data?.email,
  photo: profile?.data?.photo,
});

const menuActionHandler = ({ action }) => {
  if (action.name === 'convDeactivation') {
    if (rooms.value.filter((elt) => elt.roomId === roomId.value)?.[0]?.disabled === 0) {
      store.dispatch('chat/disableConversation', {
        id: roomId.value,
        onSuccess: () => {
          store.dispatch('chat/getConversations', { id: userDetails.value.id });
        },
      });
    } else {
      store.dispatch('chat/enableConversation', {
        id: roomId.value,
        onSuccess: () => {
          store.dispatch('chat/getConversations', { id: userDetails.value.id });
        },
      });
    }
  }
  if (action.name === 'showCalendarModal') {
    let room = rooms.value.filter((elt) => elt.roomId === roomId.value)?.[0];
    let user = room?.users[0];
    candidate.value = {
      id: parseInt(user._id.replace('user-','')),
      username: user.username,
    };
    calendarModal.value.showModal();
  }
};

const sendMessage = (event) => {
  newChat.value.content = event.content;
  // newChat.value.last_message_id = 2;
  newChat.value.conversation_id = event.roomId;
  store.dispatch('chat/saveMessage', {
    id: event.roomId,
    data: newChat.value,
  });
  roomId.value = parseInt(event.roomId, 10);
  newChat.value.content = '';
};

const fetchData = () => {
  fetchEventSource(`${VITE_MERCURE_URL}?topic=${
    encodeURIComponent(`${VITE_API_URL}/api/${scope}/${profile?.data?.id}/subscribed`)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${VITE_MERCURE_TOKEN}`,
    },
    onmessage(event) {
      const parsedData = event?.data ? JSON.parse(event.data) : null;
      if (parsedData?.data?.message?.conversation_id
          && (scope === 'recruiter' ? parsedData?.data?.message?.recruiter_id === userDetails.value.id
            : parsedData?.data?.message?.user_id === userDetails.value.id)
          && roomId.value === parsedData?.data?.message?.conversation_id && readNewMessages.value === 1) {
        store.dispatch('chat/getMessages', { id: parsedData?.data?.message?.conversation_id });
        // update chat rooms
      } else if (parsedData?.data?.message?.conversation_id
          && (scope === 'recruiter' ? parsedData?.data?.message?.recruiter_id === userDetails.value.id
            : parsedData?.data?.message?.user_id === userDetails.value.id)) {
        store.dispatch('chat/getConversations', { id: userDetails.value.id });
      }
    },
  });
};
roomId.value = route.params?.roomId ? parseInt(route.params?.roomId, 10) : roomId.value;

const jitsiLinks = ref([]);

const disableBtn = document.createElement('button');
disableBtn.classList.add('btn', 'btn-outline-danger');
disableBtn.innerHTML = 'Désactiver';

watch((chats), (value) => {
  if (value?.data?.length) {
    Object.values(value.data).forEach((room) => {
      jitsiLinks.value = [...jitsiLinks.value,
        { jitsi_room_id: room.jitsi_room_id, roomId: room.id }];
    });
    rooms.value = value.data.map((chat, indexId) => ({
      roomId: chat.id,
      roomName: scope === 'recruiter' ? `${chat?.user_name} -- ${chat?.offer?.title}`
        : `${chat?.recruiter_name} -- ${chat?.offer?.title}`,
      avatar: scope === 'recruiter' ? `${VITE_API_URL}/${chat?.user_picture}` : `${VITE_API_URL}/${chat?.company_logo}`,
      index: indexId,
      unreadCount: chat.unread_messages,
      disabled: chat.disabled,
      users: [
        {
          _id: `user-${chat?.user_id}`,
          avatar: `${VITE_API_URL}/${chat?.user_picture}`,
          username: `${chat?.user_name}`,
        },
        {
          _id: `recruiter-${chat?.recruiter_id}`,
          avatar: `${VITE_API_URL}/${chat?.company_logo}`,
          username: `${chat?.recruiter_name}`,
        },
      ],
    }));
  }
});
const onFetchMessages = (event) => {
  newChat.value.user_id = chats.value?.data[event.room?.index]?.user_id;
  newChat.value.recruiter_id = chats.value?.data[event.room?.index]?.recruiter_id;
  newChat.value.matched_id = chats.value?.data[event.room?.index]?.matched_id;
  messageLoaded.value = false;
  messages.value = [];
  store.dispatch('chat/getMessages', {
    id: event?.room?.roomId,
    onSuccess: () => {
      messageLoaded.value = true;
    },
  });
  roomId.value = parseInt(event.room?.roomId, 10);
};
watch((allMessages), (value) => {
  if (value.data) {
    messages.value = value.data?.map((message, index) => ({
      _id: message.id,
      indexId: index,
      content: message.content,
      senderId: message.sender_id,
      username: '',
      avatar: '',
      date: message?.created_at,
      timestamp: message?.time,
      system: false,
      deleted: false,
      failure: false,
      disableActions: false,
      disableReactions: false,
    }));
    // update chat rooms
    store.dispatch('chat/getConversations', { id: userDetails.value.id });
  }
});

const styles = ref({
  content: {
    background: '#2A2933',
  },
  message: {
    background: '#5A269C',
    backgroundMe: '#434059',
  },
  header: {
    background: '#1F1E26',
  },
  footer: {
    background: '#1F1E26',
  },
  sidemenu: {
    background: '#1F1E26',
  },
  room: {
    backgroundCounterBadge: '#5A269C',
  },
});

const footer = ref();
const visioBtn = document.createElement('button');
visioBtn.classList.add('btn', 'btn-purple-gradient');

const visioBtnContent = document.createElement('div');
visioBtnContent.classList.add('text-bold', 'd-flex');

const visioBtnText = document.createElement('div');
visioBtnText.innerHTML = 'Visio';
visioBtnText.className = 'me-2';

visioBtnContent.appendChild(visioBtnText);

visioBtn.appendChild(visioBtnContent);

const visioIcon = document.createElement('img');
visioBtnContent.appendChild(visioIcon);
visioIcon.src = visioIconSrc;
visioIcon.className = 'cursor-pointer';
visioBtn.addEventListener('click', () => {
  sendMessage({
    content: `Rejoignez la visio conférence en cliquant sur le lien :
    \n${VITE_PLATFORM_URL}/visio?cid=${roomId.value}&roomlink=`
        + `${jitsiLinks?.value.filter((elt) => elt.roomId === roomId.value)[0].jitsi_room_id}`,
    roomId: roomId.value,
  });
});

const textMessages = ref({
  ROOMS_EMPTY: 'Aucune conversation',
  ROOM_EMPTY: 'Aucune conversation sélectionnée',
  NEW_MESSAGES: 'Nouveaux messages',
  MESSAGE_DELETED: 'Ce message a été supprimé',
  MESSAGES_EMPTY: 'Aucun message',
  CONVERSATION_STARTED: 'La conversation a commencée le :',
  TYPE_MESSAGE: 'Tapez votre message',
  SEARCH: 'Rechercher',
  IS_ONLINE: 'est en ligne',
  LAST_SEEN: 'dernière connexion ',
  IS_TYPING: 'est en train de taper...',
  CANCEL_SELECT_MESSAGE: 'Annuler Sélection',
});

const banModalElt = ref(null);
const banModal = ref();
watch(messageErrors, (value) => {
  if (value?.errors?.content?.[0] === 'Le message contient un terme innaproprié') {
    banModal.value.show();
  }
});

const disableModalElt = ref(null);
const disableModal = ref();
watch(messageErrors, (value) => {
  if (value?.message === 'Conversation has been disabled') {
    disableModal.value.show();
    store.dispatch('chat/getConversations', { id: userDetails.value.id });
  }
});

onMounted(() => {
  store.dispatch('auth/checkAuthenticated');
  store.dispatch('chat/getConversations', { id: JSON.parse(localStorage.getItem('user')).data?.id });
  footer.value = document.querySelector('.vac-box-footer');
  footer.value.appendChild(visioBtn);
  banModal.value = new Modal(banModalElt.value);
  disableModal.value = new Modal(disableModalElt.value);
  readNewMessages.value = 1;
  fetchData();
});
onUnmounted(() => {
  readNewMessages.value = 0;
});
</script>

<style>
.deactivate-room-btn{
  position: absolute;
  right: 35px;
  bottom: -3px;
}
</style>
