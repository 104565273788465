<template>
  <div class="">
    <label
      v-if="multiple"
      class="form-label text-purple_grey text-bold"
    > Métier(s) recherché(s) <required /></label>
    <label
      v-else
      class="form-label text-purple_grey text-bold"
    > Métier souhaité <required /></label>
    <div class="input-group has-validation">
      <v-select
        v-model="formData.job"
        class="form-control v-selector"
        label="name"
        :options="jobs"
        value="option"
        :disabled="disabled"
        :multiple="multiple"
        :selectable="() => formData.job.length < 3"
        @search="search"
        @emptied="formData.job = null"
      >
        <template #option="option">
          {{ option.name }}
        </template>
        <template #no-options>
          <span v-if="inputValue.length >= 3">Pas de resultat.</span>
          <span v-else> Veuillez saisir au moins 3 caractères</span>
        </template>
      </v-select>
    </div>
    <span
      v-if="errors?.[0]"
      class="text-danger"
    >Veuillez choisir un métier valide</span>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import Required from '@/components/Required.vue';

const store = useStore();

const jobs = computed(() => store.state.job?.jobs?.data?.data);
const debounce = ref(500);
const props = defineProps({
  errors: Object,
  existingJob: [Object, String],
  disabled: Boolean,
  multiple: Boolean,
});

const formData = ref({
  job: null,
  job_id: null,
});

const inputValue = ref('');

const emit = defineEmits(['sendJob']);

const search = (value) => {
  if (value) {
    inputValue.value = value;
  }
  if (value.length >= 3) {
    setTimeout(() => {
      store.dispatch(
        'job/getAll',
        value,
      );
    }, debounce.value);
  }
};
watch(() => formData.value.job, () => {
  emit('sendJob', formData.value.job);
});

watch(() => props.existingJob, () => {
  formData.value.job = props.existingJob;
});

onMounted(() => {
  if (props.existingJob) {
    formData.value.job = props.existingJob;
  }
});

</script>
