<template>
  <div class="w-100 my-5 mb-lg-3">
    <h4 class="text-primary text-bold">
      Mon compte
    </h4>
    <hr class="w-100">
    <h5 class="my-3">
      Changement de mot de passe
    </h5>
    <p>
      Pour changer de mot de passe, veuillez cliquer sur le bouton ci-dessous. <br>
      Vous receverez par la suite un mail contenant les informations à suivre.
    </p>
    <button
      class="btn btn-purple-gradient text-bold mt-3"
      data-bs-toggle="modal"
      data-bs-target=".modal"
    >
      Changer mon mot de passe
    </button>
    <p>
      <span
        v-if="resetPasswordSuccess"
        class=" col-auto text-green"
      >
        L'email de réinitialisation de mot de passe a bien été envoyé !
      </span>
    </p>
    <modal
      class="modal"
      :title="'Réinitialisation de mot de passe'"
      :text="'Etes-vous sûr de vouloir réinitialiser votre mot de passe ?'"
      :action-button="'Réinitialiser mon mot de passe'"
      @callback="resetPassword"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import Modal from '@/components/modal/Modal.vue';
import { ref } from 'vue';

const store = useStore();

const user = JSON.parse(localStorage.getItem('user'))?.data;
const resetPasswordSuccess = ref(false);

const resetPassword = () => store.dispatch(
  'auth/forgotPassword',
  {
    email: user.email,
    guard: 'user',
    onSuccess: () => {
      resetPasswordSuccess.value = true;
    },
  },
);
</script>
