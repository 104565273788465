export default {
  setMeetings(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.meetings = value;
  },
  setMeetingsErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.meetings_errors = value;
  },
  setMeetingsLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.meetings_loading = value;
  },
  setMeetingDetail(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.meeting_detail = value;
  },
  setMeetingDetailErrors(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.meeting_detail_errors = value;
  },
  setMeetingDetailLoading(state, value) {
    // eslint-disable-next-line no-param-reassign
    state.meeting_detail_loading = value;
  },
};