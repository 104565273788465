<template>
  <div>
    <button
      class="btn btn-purple-gradient text-bold w-100"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#subscription-modal"
    >
      <span v-if="!video">Ajouter une vidéo de motivation</span>
      <span v-else>Voir la vidéo de motivation</span>
    </button>
    <div
      id="subscription-modal"
      class=" modal fade"
      data-bs-backdrop="false"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content  bg-dark_grey">
          <div class="modal-header">
            <h5 class="modal-title">
              Vidéo de motivation
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body my-3 d-flex flex-column justify-content-center align-items-center flex-wrap">
            <video
              v-if="video"
              controls
              :src="`${VITE_API_URL}/${video}`"
              class="w-100"
            />
            <record-viewer
              ref="recordViewer"
              :key="key"
              :update-mode="!!video"
              :success-message="successMessage"
              :loading="loading"
              @record-output="processRecordOutput"
            />
            <button
              v-if="recording"
              class="btn btn-light text-bold d-flex
        justify-content-center align-items-center mt-2"
              @click="stopRecording"
            >
              Retour
            </button>
            <p
              v-if="!recording"
              class="mt-2 mb-0"
            >
              OU
            </p>
            <video-import-modal
              v-if="!recording"
              ref="videoImportModal"
              :update-mode="!!video"
              :success-import-message="successImportMessage"
              :update-errors="videoUploadErrors"
              :loading="loading"
              @send-video="sendVideo"
            />
            <!--        <img-->
            <!--          src="@/assets/images/icons/camera-video.svg"-->
            <!--          alt="Enregistrer une vidéo"-->
            <!--          class="me-2"-->
            <!--        >-->
            <!--      <span>Enregistrer la vidéo</span>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import RecordViewer from '@/components/record/RecordViewer.vue';
import VideoImportModal from '@/components/modal/VideoImportModal.vue';

const props = defineProps({
  video: String,
  cancelButton: String,
});
const choice = ref({
  subscription: null,
});

const store = useStore();
const route = useRoute();

const { VITE_API_URL } = import.meta.env;

const successMessage = ref(false);
const successImportMessage = ref(false);

const videoImportModal = ref();
const recordViewer = ref();
const key = ref(1);
const loading = ref(false);

const recording = computed(() => recordViewer?.value?.recording);

const emit = defineEmits(['on-change-video']);

const videoUploadErrors = computed(() => store.state.application.application_detail_errors);

const processRecordOutput = (blob) => {
  props.video ? updateVideo(blob) : uploadVideo(blob);
};

const sendVideo = (blob) => {
  props.video ? updateVideo(blob, true) : uploadVideo(blob, true);
};

const uploadVideo = (blob, fromModal = false) => {
  loading.value = true;
  const file = new File([blob], 'record');
  const formData = new FormData();
  formData.append('video', file);
  formData.append('type', props.type);
  store.dispatch('application/addVideo', {
    id: route.params.id,
    video: formData,
    onSuccess: () => {
      loading.value = false;
      store.dispatch('application/getOne', route.params.id);
      if (fromModal) {
        videoImportModal.value.hideCollapse();
        successImportMessage.value = true;
      } else {
        successMessage.value = true;
      }
    },
  });
};

const updateVideo = (blob, fromModal = false) => {
  loading.value = true;
  const file = new File([blob], 'record');
  const formData = new FormData();
  formData.append('video', file);
  formData.append('type', props.type);
  successMessage.value = false;
  // formData.append('type', props.type);
  store.dispatch('application/updateVideo', {
    id: route.params.id,
    video: formData,
    onSuccess: () => {
      loading.value = false;
      store.dispatch('application/getOne', route.params.id);
      if (fromModal) {
        videoImportModal.value.hideCollapse();
        successImportMessage.value = true;
      } else {
        successMessage.value = true;
      }
    },
  });
};

const stopRecording = () => {
  successMessage.value = false;
  key.value += 1;
};

onMounted(() => {
  successMessage.value = false;
});

</script>
